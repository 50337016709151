import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { forgotPasswordPayloadModel, loginPayloadModel, userLogoutResponseDataModel, UserRegistrationPayloadModel } from '../models/axiosModels';
import { getRevenuePayloadData, MarketAndSalesResourcePayloadDataModel } from '../models/dashboardAxiosModels';


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_CALL_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";


let apiBuffer: Array<{ name: string, controller: AbortController }> = [];

const responseBody = (response: any) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err: AxiosError) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        console.log("response.response.data.error--", err?.response?.data)
    }
    return err;
}

const requests = {

    get: async (url: string) => {
        try {
            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            const response = await axios.get(url,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    signal: controller.signal
                });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        }
        catch (err: any) {
            return Error(err);
        }
    },

    fileUploadPost: async (url: string, body: any) => {
        try {
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            return responseBody(response);
        }
        catch (err: any) {
            return Error(err);
        }
    },

    post: async (url: string, body: any, config?: AxiosRequestConfig<any>) => {
        try {
            const controller = new AbortController();
            apiBuffer.push({ name: url, controller: controller })
            let additionalConfig: AxiosRequestConfig<any> = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            if (config) {
                additionalConfig = {
                    ...config,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
            const response = await axios.post(url, body, additionalConfig);
            return responseBody(response);
        }
        catch (err: any) {
            if (axios.isCancel(err)) {
                console.log("Request was canceled", err);
                // @ts-ignore
                apiBuffer = apiBuffer.filter((api: any) => api.name !== url)
            } else {
                console.log("not aborted", err)
            }
            return Error(err);
        }
    },

    // post: async (url: string, body: any, onAbort?: () => void, config?: any) => {
    //     try {
    //         // @ts-ignore
    //         const controller = new AbortController();
    //         // @ts-ignore
    //         apiBuffer.push({ name: url, controller: controller })
    //         // @ts-ignore
    //         const response = await axios.post(url, body, {
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             signal: controller.signal,
    //             ...config,
    //         });
    //         return responseBody(response);

    //         // return response.data;
    //     }
    //     catch (err: any) {
    //         if (axios.isCancel(err)) {
    //             console.log("Request was canceled", err);
    //             // @ts-ignore
    //             apiBuffer = apiBuffer.filter((api: any) => api.name !== url)
    //             if (onAbort) console.log("onabort called");
    //         } else {
    //             console.log("not aborted", err)
    //         }
    //         return Error(err);
    //     }
    // },

    put: async (url: string, body: any) => {
        try {
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },

    // delete: async (url: string) => {
    //     try {
    //         const response = await axios.delete(url, {
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         });
    //         return responseBody(response);
    //     } catch (err: any) {
    //         return Error(err);
    //     }
    // }

    delete: async (url: string, payload?: any) => {
        try {
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                },
                data: payload,
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    }

};

const signInAPI = {
    post: (payload: loginPayloadModel) => requests.post("api/user/login", payload)
}

const userDetailAPI = {
    get: () => requests.get("api/user/get-user-detail")
}

const userRegistrationAPI = {
    post: (payload: UserRegistrationPayloadModel) => requests.post("api/user/register", payload)
}

const logoutAPI = {
    get: () => requests.get("api/user/logout-user")
}

const forgotPasswordAPI = {
    post: (payload: forgotPasswordPayloadModel) => requests.post("api/user/forgot-password", payload)
}

const getUserDashboardAPI = {
    get: () => requests.get("api/dashboard")
}

const getMarketingViewDetailsAPI = {
    get: () => requests.get("api/dashboard/marketing-view-details")
}

const getUserMarketingViewAPI = {
    get: () => requests.get("api/dashboard/marketing-view")
}

const getUserMarketingPortfolioAPI = {
    get: () => requests.get("api/dashboard/marketing-portfolio")
}

const getRevenueDataAPI = {
    post: (payload: getRevenuePayloadData) => requests.post("api/revenue", payload)
}

const revenueGeneratePdfAPI = {
    post: (payload: getRevenuePayloadData) => requests.post("api/revenue/generate-pdf", payload, { responseType: 'blob', })
}

const getRedTailSsoAPI = {
    get: () => requests.get("api/sso/get-redtail-sso")
}

const redtailCredentialAPI = {
    post: (payload: any) => requests.post("api/sso/save-redtail-cred", payload)
}

const getBrokersAllianceSSOAPI = {
    get: () => requests.get("api/sso/get-brokersalliance-sso")
}

const getLifeBrainSSOAPI = {
    get: () => requests.get("api/sso/get-lifebrain-sso")
}

const getRetirementBrainSSOAPI = {
    get: () => requests.get("api/sso/get-retirementbrain-sso")
}

const getMarketAndSalesDetailsAPI = {
    get: () => requests.get("/api/market-and-sales")
}

const marketAndSalesResourceAPI = {
    post: (payload: MarketAndSalesResourcePayloadDataModel) => requests.post("/api/market-and-sales", payload)
}

const marketAndSalesResourceUpdateAPI = {
    put: (payload: MarketAndSalesResourcePayloadDataModel) => requests.put("/api/market-and-sales", payload)
}

const uploadResourceFileAPI = {
    post: (payload: any) => requests.fileUploadPost("/api/google-cloud", payload)
}

const deleteMarketAndSalesSubject = {
    delete: (payload: any) => requests.delete("/api/market-and-sales", payload)
}


const agent = {
    signInAPI,
    userDetailAPI,
    userRegistrationAPI,
    logoutAPI,
    forgotPasswordAPI,
    getUserDashboardAPI,
    getMarketingViewDetailsAPI,
    getUserMarketingViewAPI,
    getUserMarketingPortfolioAPI,
    getRevenueDataAPI,
    revenueGeneratePdfAPI,
    getRedTailSsoAPI,
    redtailCredentialAPI,
    getBrokersAllianceSSOAPI,
    getLifeBrainSSOAPI,
    getRetirementBrainSSOAPI,
    getMarketAndSalesDetailsAPI,
    marketAndSalesResourceAPI,
    marketAndSalesResourceUpdateAPI,
    uploadResourceFileAPI,
    deleteMarketAndSalesSubject
};

export default agent;


