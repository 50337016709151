import React from 'react'
import Grid from '@mui/material/Grid2';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "../../Styles/ComponentStyles/GlobalComponentStyles/AppClientMarketingDialog.module.scss"
import dashboardStyles from "../../Styles/ComponentStyles/MainPages/dashboard.module.scss"
import globalStyles from "../../Styles/global.module.scss"
import { Divider, Typography, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useIsBelow600 } from '../../Utils/Constant/Constant';


const ClientMarketingAndCorrespondenceDialog = (props: any) => {

    const { setFlagSSODialog, radtailLinkData, setCurrentAppUrl, getRedtailSSO, showAppName, setFlagCredentialDialog, headerName } = props

    const isBelow600 = useIsBelow600();
    const isBelow768 = useMediaQuery('(max-width : 768px) and (min-width : 0px)')


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }}>
                    <Grid container sx={{ justifyContent: 'space-between', gap: isBelow600 ? "15px" : "0px" }}>
                        <Grid size={{ xl: 7, lg: 7, md: 6, sm: isBelow768 ? 5 : 6.5, xs: 12 }}>
                            <Typography component={'span'}
                                className={`${globalStyles.gblCWhite} ${isBelow600 ? globalStyles.globalTextV17 : globalStyles.globalTextV20}`}
                            >
                                {headerName}
                            </Typography>
                        </Grid>
                        <Grid size={{ xl: 4.5, lg: 4.5, md: 5, sm: isBelow768 ? 6 : 5, xs: 12 }}>
                            <Grid container className={`${styles.cmHeaderContainer}`}>
                                <Grid>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14}`}
                                    >
                                        Status
                                    </Typography>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.globalTextV14}`}
                                        sx={{ color: showAppName.status == "Active" ? "green" : "White" }}
                                    >
                                        :&nbsp;{showAppName.status}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <GlobalButton
                                        value='Update Redtail Credentials'
                                        size={"medium"}
                                        className={dashboardStyles.updateCredButton}
                                        onClick={() => {
                                            setFlagCredentialDialog(true);
                                            setFlagSSODialog(false)
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        {!isBelow768 &&
                            <Grid size={{ xs: 0.5 }}>
                                <Grid container sx={{ justifyContent: "end", alignItems: "center", height: "100%" }}>
                                    <Close sx={{ color: "white" }} />
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>

                <Grid size={{ xs: 12 }} sx={{ margin: '10px 0px' }}>
                    <Divider sx={{ borderColor: "white" }} />
                </Grid>

                <Grid size={{ xs: 12 }} sx={{ padding: "20px 0px" }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "40px 0px 20px" }}>
                            <Grid container sx={{ justifyContent: 'space-between', rowGap: "20px", }}>
                                {radtailLinkData && radtailLinkData.length > 0 && (
                                    radtailLinkData.map((ele: any) => {
                                        const lastPartOfUrl = ele.url.split('/').pop();
                                        return (
                                            <>
                                                <Grid size={{ xl: 5.8, lg: 5.8, md: 5.8, sm: 12, xs: 12 }}
                                                    sx={{
                                                        border: '1px solid white',
                                                        padding: '15px',
                                                        borderRadius: "8px",
                                                        transition: 'border-color 0.3s ease',
                                                        '&:hover': {
                                                            borderColor: '#1b76af',
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                >
                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                                        <Grid className={`${styles.iconItem}`}>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: ele.icon }}
                                                            />
                                                        </Grid>
                                                        <Grid className={`${styles.titleItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${styles.ssoTitleLabel}`}
                                                            >
                                                                {ele.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid className={`${styles.btnItem}`}>
                                                            <GlobalButton
                                                                fullWidth
                                                                value='View'
                                                                endIcon={<ArrowForwardIcon sx={{ color: "#273348" }} />}
                                                                size='medium'
                                                                className={`${styles.globalSsoBtnStyles}`}
                                                                onClick={() => {
                                                                    setCurrentAppUrl(lastPartOfUrl)
                                                                    getRedtailSSO(lastPartOfUrl)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    })
                                )}
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} sx={{ mt: "10px" }}>
                            <Grid container sx={{ justifyContent: "end", gap: "20px" }}>
                                <Grid sx={{ maxWidth: isBelow600 ? "90px" : "130px", width: isBelow600 ? "90px" : "130px" }}>
                                    <GlobalButton
                                        fullWidth
                                        value='Close'
                                        size='large'
                                        className={`${styles.closeBtnStyle}`}
                                        onClick={() => {
                                            setCurrentAppUrl(null)
                                            setFlagSSODialog(false)
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ maxWidth: "130px", width: "130px" }}>
                                    <GlobalButton
                                        fullWidth
                                        value='Uninstall'
                                        size='large'
                                        className={`${styles.globalSsoBtnStyles}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ClientMarketingAndCorrespondenceDialog