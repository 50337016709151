import React from 'react'
import styles from "../../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { List, ListItem, Typography } from '@mui/material'



const ModuleList = (props: any) => {

    const { menuItems, isProfileAdmin, handleNavigate, currentSelectedTab, drawerOpen, mobileOpen } = props

    return (
        <>
            <List className={`${styles.navigationList}`}>
                {menuItems && menuItems?.filter((item: any) => item?.roles?.includes(isProfileAdmin ? "admin" : "user"))?.map((item: any) => (
                    <ListItem
                        key={item.name}
                        onClick={() => {
                            if (item.onClick) {
                                item.onClick(); 
                            } else {
                                handleNavigate(item.path); 
                            }
                    }}
                        className={`${styles.sideNavigationList}`}
                        sx={{
                            backgroundColor: currentSelectedTab === item.name ? "#ffffff" : "transparent",
                            color: currentSelectedTab === item.name ? "#000000" : "#ffffff",
                        }}
                    >
                        <Grid size={{ xs: 12 }}>
                            <Grid container sx={{ gap: (drawerOpen || mobileOpen) ? "15px" : "0", justifyContent: 'center' }}>
                                <Grid className={`${styles.sideNavigationNameIcon}`}>
                                    <div>
                                        {currentSelectedTab === item.name ? item.selectedIcon : item.icon}
                                    </div>
                                </Grid>
                                <Grid className={`${styles.sideNavigationName}`}>
                                    {(drawerOpen || mobileOpen) ? (
                                        <Typography component='span'>
                                            {item.name}
                                        </Typography>
                                    ) : (<></>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </>
    )
}

export default ModuleList