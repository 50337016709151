
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect } from 'react'
import globalStyles from '../Styles/global.module.scss';
import SideNavigationPanel from '../Components/SideNavigationPanel/SideNavigationPanel';
import { Outlet } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../Context/GlobalUserDetailContext';
import AdminAppNavbar from '../Components/AdminAppNavbar/AdminAppNavbar';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import AppNavbar from '../Components/AppNavbar/AppNavbar';
import MenuIcon from '@mui/icons-material/Menu';
import { useIsBelow1200 } from '../Utils/Constant/Constant';
// import { isBelow1200 } from '../Utils/Constant/Constant';


const MainComponent = () => {

    const { drawerOpen,
        setDrawerOpen,
        userProfileDetails,
        currentSelectedTab,
        isAdminNavbarVisible,
        userRole
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const isProfileAdmin = userProfileDetails?.user?.flagAdmin
    const isBelow1200 = useIsBelow1200()
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setMobileOpen(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    return (
        <>
            <Grid container className={`${globalStyles.mainContainer}`}>
                {/* {isProfileAdmin == false &&
                    <Grid size={{ xs: 12 }} className={`${globalStyles.appBarNav}`}>
                        <AdminAppNavbar />
                    </Grid>
                } */}
                {/* <Grid size={{ xs: 12 }} className={`${isProfileAdmin == false ? globalStyles.fullHeightForAdminNav : globalStyles.fullHeightStyle} `}> */}
                <Grid size={{ xs: 12 }} className={`${globalStyles.fullHeightStyle}`}>
                    <Grid container className={`${globalStyles.fullHeightStyle}`}>
                        <Grid
                            className={`${globalStyles.fullHeightStyle}`}
                            sx={{
                                width: `${isBelow1200 ? "0px" : drawerOpen ? "250px" : '80px'}`
                            }}
                        >
                            <SideNavigationPanel
                                handleDrawerClose={handleDrawerClose}
                                mobileOpen={mobileOpen}
                            />
                        </Grid>
                        <Grid
                            className={`${globalStyles.fullHeightStyle} ${globalStyles.globalContentBgWrapper}`}
                            sx={{
                                width: `${isBelow1200 ? "100%" : drawerOpen ? "calc(100% - 250px) !important" : "calc(100% - 80px) !important"}`,
                                maxWidth: `${isBelow1200 ? "100%" : drawerOpen ? "calc(100% - 250px) !important" : "calc(100% - 80px) !important"}`,
                                overflow: 'auto',
                                // marginTop: isBelow1200 ? "70px" : "0px"
                            }}
                        >
                            <Grid container className={`${globalStyles.gblHeaderOutletContainer}`}
                                sx={{ backgroundColor: isBelow1200 ? "#273348" : "transparent" }}
                            >
                                <Grid size={{ xs: 12 }}>
                                    <Grid container
                                        sx={{
                                            alignItems: "baseline !important",
                                            gap: "5px",
                                            padding: isBelow1200 ? "15px 10px" : "0px 20px"
                                        }}
                                    >
                                        {isBelow1200 && (
                                            <Grid sx={{ width: "30px", maxWidth: "30px" }}>
                                                <IconButton
                                                    sx={{ padding: "0px", margin: "0px", }}
                                                    onClick={handleDrawerToggle}
                                                >
                                                    <MenuIcon sx={{ color: "white", paddingBottom: "5px" }} />
                                                </IconButton>
                                            </Grid>)
                                        }
                                        <Grid size={{ xs: "grow" }}>
                                            <AppNavbar
                                                flagShowDivider={isBelow1200 ? false : true}
                                                isBelow1200={isBelow1200}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid size={{ xs: 12 }} className={`${globalStyles.globalOutletWrapper}`}>
                                    <Outlet />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MainComponent