import React, { useState, useRef, useEffect } from "react";
import { IconButton, LinearProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import jpgIcon from "../../Assets/General/jpgImage.png";
import globalStyles from "../../Styles/global.module.scss";
import { Accept, useDropzone } from "react-dropzone";

// Define the file type for accepted files
interface GlobalDropZoneProps {
  onFileSelect: (file: File | null) => void;
  disabled?: boolean;
  uploadProgress?: number | null;
}

const GlobalDropZone: React.FC<any> = ({
  handleFileSelect, disabled, uploadProgress, selectedFile, allowedType
}) => {


  const [file, setFile] = useState<any | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const acceptedFileTypes: Accept =
    allowedType === "pdf"
      ? { "application/pdf": [".pdf"] }
      : { "image/png": [".png"], "image/jpeg": [".jpeg"], "image/jpg": [".jpg"] };
  const maxSize = allowedType === "pdf" ? 30 * 1024 * 1024 : 5 * 1024 * 1024;

  useEffect(() => {
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  }, [selectedFile]);

  const formatFileSize = (size: number) => {
    return `${Math.ceil(size / 1024)} KB`; // Convert bytes to KB and round up
  };


  const onDrop = (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    if (!selectedFile) return;

    // Validate file type
    const isPdf = selectedFile.type === "application/pdf";
    const isImage = ["image/png", "image/jpeg", "image/jpg"].includes(selectedFile.type);

    if (allowedType === "pdf" && !isPdf) {
      console.log("Only PDF files are allowed.");
      return;
    }
    if (allowedType === "image" && !isImage) {
      alert("Only image files (PNG, JPG, JPEG) are allowed.");
      return;
    }

    // Validate file size
    if (selectedFile.size > maxSize) {
      alert(`File size must be less than ${allowedType === "pdf" ? "30MB" : "5MB"}.`);
      return;
    }

    handleFileSelect(selectedFile);
  };


  const handleCancel = () => {
    setFile(null);
    handleFileSelect(null);
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes as Accept,
    disabled,
  });

  return (
    <Grid
      container
      {...getRootProps()}
      sx={{
        border: "1px dashed #FFFFFF",
        backgroundColor: "#FFFFFF4D",
        borderRadius: "5px",
        textAlign: "center",
        padding: "20px ",
        width: "100%",
      }}
    >
      <input {...getInputProps()} ref={fileInputRef} style={{ display: "none" }} />


      {!file ? (
        <Grid size={{ xs: 12 }} >
          <Grid container sx={{ alignItems: "center", justifyContent: "center", columnGap: "10px", }}>
            <Grid>
              <CloudUploadOutlinedIcon
                sx={{ fontSize: "30px", color: "white" }}
              />
            </Grid>
            <Grid>
              <Typography
                component={"span"}
                className={`${globalStyles.globalTextV15} ${globalStyles.gblCWhite}`}
              >
                Drag & Drop an {allowedType == "image" ? "image" : allowedType == "pdf" ? "pdf" : ""} or{" "}
                <span style={{ color: "#B1D8FF", cursor: "pointer" }} onClick={handleBrowseClick}>
                  Browse
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid size={{ xs: 12 }}>
            <Grid container sx={{ alignItems: "center", justifyContent: "space-between", }}>
              <Grid size={{ xs: 1 }}>
                <img src={jpgIcon} width="100%" />
              </Grid>

              <Grid size={{ xs: 9.5 }}>
                <Grid container textAlign="left">
                  <Grid size={{ xs: 12 }}>
                    <Typography
                      component={"span"}
                      className={`${globalStyles.globalTextV15} ${globalStyles.gblCWhite}`}
                    >
                      {file?.google_storage_original_file_name}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <Typography
                      component={"span"}
                      className={`${globalStyles.globalTextV14} ${globalStyles.gblC7C7C7}`}
                    >
                      {formatFileSize(file?.google_storage_file_size)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid size={{ xs: 1 }}>
                <IconButton onClick={handleCancel} color="error" disabled={disabled}>
                  <CancelOutlinedIcon sx={{ color: "#FFFFFF" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

        </>
      )}

      {uploadProgress && (
        <Grid size={{ xs: 12 }}>
          <LinearProgress />
        </Grid>
      )}

    </Grid>
  );
};

// {
//   uploadProgress < 100 && (
//     <Grid
//       container
//       sx={{
//         alignItems: "center",
//         width: "100%",
//         columnGap: "10px",
//       }}
//     >
//       <Grid size={{ xs: 10 }}>
//         <LinearProgress
//           variant="determinate"
//           value={uploadProgress}
//           sx={{
//             height: 8,
//             borderRadius: 4,
//             backgroundColor: "#8E8E8E",
//             "& .MuiLinearProgress-bar": {
//               backgroundColor: "#FFFFFF",
//             },
//           }}
//         />
//       </Grid>
//       <Grid size={{ xs: 1 }}>
//         <Typography
//           component={"span"}
//           className={`${globalStyles.globalTextV15} ${globalStyles.gblCWhite}`}
//         >
//           {uploadProgress}%
//         </Typography>
//       </Grid>
//     </Grid>
//   )
// }

export default GlobalDropZone;
