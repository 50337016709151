import React, { useContext, useEffect, useState } from "react";

import globalStyles from "../../Styles/global.module.scss";
import dashboardStyles from "../../Styles/ComponentStyles/MainPages/dashboard.module.scss";
import portfolioStyles from "../../Styles/ComponentStyles/GlobalComponentStyles/PortfolioDialog.module.scss";
import resourceStyles from "../../Styles/ComponentStyles/GlobalComponentStyles/NewResourceDialog.module.scss";

import {
    IconButton,
    Typography,
    Divider,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import { Category, Close as CloseIcon } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

import GlobalButton from "../../Utils/Layout/GlobalButton/GlobalButton";
import GlobalDropZone from "../../Utils/Layout/GlobalDropZone";
import GlobalTextfield from "../../Utils/Layout/GlobalTextfield/GlobalTextfield";
import GlobalAutocomplate from "../../Utils/Layout/GlobalAutocomplate/GlobalAutoComplate";
import { DashboardContext, DashboardDetailPayload } from "../../Context/DashboardDetailContext";
import agent from "../../Services/api";
import GlobalSymbols from "../../Utils/Layout/GlobalSymbols/GlobalSymbols";
import { MarketAndSalesResourcePayloadDataModel } from "../../models/dashboardAxiosModels";
import { GlobalUserContext, GlobalUserContextPayload } from "../../Context/GlobalUserDetailContext";
import { useSnackbar } from "../../Utils/Layout/GlobalSnackbar/GlobalSnackbar";

function MarketAndSalesResourceDialog(props: any) {

    const { setFlagNewResourceDialog, flag
    } = props

    const {
        setFlagLoader,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        uploadResourceFormData,
        setUploadResourceFormData,
        // uploadResourceFile,
        getMarketAndSalesDetails,
        marketingAndSalesResorcePayloadDetails,
        setMarketingAndSalesResorcePayloadDetails,

        setMarketAndSalesResourceDetails,
    } = useContext(DashboardContext) as DashboardDetailPayload


    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const { showSnackbar } = useSnackbar();

    const [selectedAttachFile, setSelectedAttachFile] = useState<any>(null);
    const [selectedPDFThumbnail, setSelectedPDFThumbnail] = useState<any>(null);
    const [uploadProgress, setUploadProgress] = useState<boolean>(false);
    const [renderSection, setRenderSection] = useState<string | File | null>("pdf");
    const [uploadProgressAttach, setUploadProgressAttach] = useState<any>(null);
    const [uploadProgressThumbnail, setUploadProgressThumbnail] = useState<any>(null);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRenderSection(event.target.value == "pdf" ? "pdf" : event.target.value == "hyperlink" ? "hyperlink" : null);
    };

    const handleFileSelect = (file: File | null, type: 'attach' | 'thumbnail') => {
        if (file && file.size > MAX_FILE_SIZE) {
            alert("File size must be less than 2MB");
            if (type === 'attach') {
                setSelectedAttachFile(null);
            } else {
                setSelectedPDFThumbnail(null);
            }
            return;
        }
        if (file) {
            handleUploadFile(file, type);
        }
    };

    const handleUploadFile = async (file: File, type: 'attach' | 'thumbnail') => {
        if (!file) return;

        if (type === 'attach') {
            setUploadProgressAttach(true);
        } else {
            setUploadProgressThumbnail(true);
        }

        const formData = new FormData();
        formData.append("file", file);
        const response = await agent.uploadResourceFileAPI.post(formData)

        if (response?.status) {
            console.log("Upload successful:", response);
            const responseData = response?.data

            if (type === 'attach') {
                setSelectedAttachFile(responseData);
                setUploadProgressAttach(false);
            } else {
                setSelectedPDFThumbnail(responseData);
                setUploadProgressThumbnail(false)
            }
        }
        else {
            console.error("Upload failed:", response);
            if (type === 'attach') {
                setSelectedAttachFile(null);
                setUploadProgressAttach(null); // Reset progress
            } else {
                setSelectedPDFThumbnail(null);
                setUploadProgressThumbnail(null); // Reset progress
            }
        }
    };

    const handleAddMarketingResource = async () => {

        const payload: MarketAndSalesResourcePayloadDataModel = {
            categoryName: marketingAndSalesResorcePayloadDetails?.categoryName ?? "",
            categoryID: marketingAndSalesResorcePayloadDetails?.categoryID ?? null,
            description: marketingAndSalesResorcePayloadDetails?.description ?? "",
            hyperlink: marketingAndSalesResorcePayloadDetails?.hyperlink ?? null,
            hyperlink_name: marketingAndSalesResorcePayloadDetails?.hyperlink_name ?? null,
            google_storage_file_id: selectedAttachFile?.google_storage_id ?? null,
            google_storage_thumbnail_id: selectedPDFThumbnail?.google_storage_id ?? null
        };

        setFlagLoader(true)
        const response = await agent.marketAndSalesResourceAPI.post(payload)
        if (response?.status && response?.data) {
            const response = await getMarketAndSalesDetails();
            console.log("response of getMarketAndSalesDetails", response);
            showSnackbar("New Resource Added Successfully", "success")
            if (response?.status) {
                if (response?.data && Array.isArray(response.data)) {
                    setMarketAndSalesResourceDetails(response.data);
                }
                setFlagLoader(false)
            }
            else {
                const error = response?.data?.error?.message || "Something Went Wrong!"
                setMarketAndSalesResourceDetails([]);
                showSnackbar(error, "error")
                setFlagLoader(false)
            }
        }
        else {
            const error = response?.data?.error?.message || "Something Went Wrong!"
            showSnackbar(error, "error")
            setFlagLoader(false)
        }
        setFlagNewResourceDialog(false)
    }

    return (
        <>
            <Grid container sx={{ padding: '20px 0px' }}>
                <Grid size={{ xs: 12 }}>
                    <Grid container>

                        {flag === "add-new-resource" &&
                            <>
                                <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                            >
                                                Category Name
                                            </Typography><GlobalSymbols symbol="astric" />
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <GlobalTextfield
                                                placeholder="Enter Category Name"
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={marketingAndSalesResorcePayloadDetails?.categoryName ?? ""}
                                                onChange={(e) =>
                                                    setMarketingAndSalesResorcePayloadDetails((prev: any) => ({
                                                        ...prev,
                                                        categoryName: e.target.value,
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                            >
                                                Upload Format
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <RadioGroup row name="upload-format" value={renderSection} onChange={handleRadioChange}>
                                                <FormControlLabel
                                                    value="pdf"
                                                    control={<Radio sx={{ color: "white", "&.Mui-checked": { color: "white" } }} />}
                                                    label="PDF"
                                                    sx={{ color: "white" }}
                                                />
                                                <FormControlLabel
                                                    value="hyperlink"
                                                    control={<Radio sx={{ color: "white", "&.Mui-checked": { color: "white" } }} />}
                                                    label="HyperLink"
                                                    sx={{ color: "white" }}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {renderSection == "pdf" &&
                            <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                                <Grid container className={`${resourceStyles.imageContainer}`}>

                                    <Grid size={{ xs: 12, sm: 5.8 }}>
                                        <Grid container sx={{ margin: "0px 0px 10px", alignItems: "center" }}>
                                            <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                                <Typography
                                                    component={"span"}
                                                    className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                                >
                                                    Attach File <GlobalSymbols symbol="astric" />
                                                </Typography>
                                            </Grid>

                                            <Grid size={{ xs: 12 }} >
                                                <GlobalDropZone
                                                    selectedFile={selectedAttachFile}
                                                    handleFileSelect={(file: File | null) => handleFileSelect(file, 'attach')}
                                                    disabled={uploadProgress}
                                                    uploadProgress={uploadProgressAttach}
                                                    allowedType="pdf"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid size={{ xs: 12, sm: 5.8 }}>
                                        <Grid
                                            container
                                            sx={{ margin: "0px 0px 10px", alignItems: "center" }}
                                        >
                                            <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                                <Typography
                                                    component={"span"}
                                                    className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                                >
                                                    PDF Thumbnail
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>
                                                <GlobalDropZone
                                                    selectedFile={selectedPDFThumbnail}
                                                    handleFileSelect={(file: File | null) => handleFileSelect(file, 'thumbnail')}
                                                    disabled={uploadProgress}
                                                    uploadProgress={uploadProgressThumbnail}
                                                    allowedType="image"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {renderSection == "hyperlink" &&
                            <>
                                <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                            >
                                                Hyperlink Name <GlobalSymbols symbol="astric" />
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                            <GlobalTextfield
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={marketingAndSalesResorcePayloadDetails?.hyperlink_name ?? ""}
                                                onChange={(e) =>
                                                    setMarketingAndSalesResorcePayloadDetails((prev: any) => ({
                                                        ...prev,
                                                        hyperlink_name: e.target.value,
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                            <Typography
                                                component={"span"}
                                                className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                            >
                                                Add Hyperlink <GlobalSymbols symbol="astric" />
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ marginBottom: "10px" }}>
                                            <GlobalTextfield
                                                fullWidth
                                                size="medium"
                                                autoComplete="off"
                                                value={marketingAndSalesResorcePayloadDetails?.hyperlink ?? ""}
                                                onChange={(e) =>
                                                    setMarketingAndSalesResorcePayloadDetails((prev: any) => ({
                                                        ...prev,
                                                        hyperlink: e.target.value,
                                                        google_storage_file_id: null
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                    <Typography
                                        component={"span"}
                                        className={`${globalStyles.globalTextV17} ${globalStyles.gblCWhite}`}
                                    >
                                        Description <GlobalSymbols symbol="astric" />
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <GlobalTextfield
                                        placeholder="Enter Description"
                                        fullWidth
                                        size="medium"
                                        autoComplete="off"
                                        multiline={true}
                                        maxRows={3}
                                        value={marketingAndSalesResorcePayloadDetails?.description ?? ""}
                                        onChange={(e) =>
                                            setMarketingAndSalesResorcePayloadDetails((prev: any) => ({
                                                ...prev,
                                                description: e.target.value,
                                            }))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }} className={`${resourceStyles.gblItemSpacing}`}>
                            <Grid container className={`${portfolioStyles.btnContainers}`}>
                                <Grid>
                                    <GlobalButton
                                        value="Submit"
                                        size="large"
                                        className={`${dashboardStyles.raSubmitBtn}`}
                                        onClick={() => { handleAddMarketingResource() }}
                                    />
                                </Grid>
                                <Grid>
                                    <GlobalButton
                                        value="Close"
                                        // onClick={onClose}
                                        size="large"
                                        className={`${resourceStyles.closeBtn}`}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MarketAndSalesResourceDialog