import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "../../Styles/ComponentStyles/MainPages/dashboard.module.scss";
import globalStyles from "../../Styles/global.module.scss";
import Icons from "../../Utils/Layout/Icons/Icons";
import { IconButton, Typography, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { data, useIsBelow1200 } from "../../Utils/Constant/Constant";
import GlobalDialog from "../../Utils/Layout/GlobalDialog/GlobalDialog";
import GlobalWarningDialog from "../../Utils/Layout/GlobalWarningDialog/GlobalWarningDialog";
import MarketAndSalesResourceDialog from "../Dialogs/MarketAndSalesResourceDialog";
import { MarketAndSalesResourcePayloadDataModel } from "../../models/dashboardAxiosModels";
import { DashboardContext, DashboardDetailPayload } from "../../Context/DashboardDetailContext";
import { useSnackbar } from "../../Utils/Layout/GlobalSnackbar/GlobalSnackbar";
import { GlobalUserContext, GlobalUserContextPayload } from "../../Context/GlobalUserDetailContext";
import agent from "../../Services/api";

interface pdfCardsProps {
  pdfMainName: string;
  pdfSubName: string;
  pdfUrl: string;
  data: any;
}

interface DeleteInterface {
  marketandsales_pdf_new_id: number | null;
}


const PdfCards = (props: pdfCardsProps) => {

  const { showSnackbar } = useSnackbar();

  const {
    setFlagLoader,
  } = useContext(GlobalUserContext) as GlobalUserContextPayload

  const {
    getMarketAndSalesDetails,

    marketAndSalesResourceDetails, setMarketAndSalesResourceDetails
  } = useContext(DashboardContext) as DashboardDetailPayload;

  // console.log("hiii", marketAndSalesResourceDetails)

  const { pdfMainName, pdfSubName, pdfUrl, data } = props;
  const isBelow1200 = useIsBelow1200();

  const [flagOpenPdfDialog, setFlagOpenPdfDialog] = useState<boolean>(false);
  const [flagOpenEditDialog, setFlagOpenEditDialog] = useState<boolean>(false);
  const [flagOpenDeleteDialog, setFlagOpenDeleteDialog] = useState<boolean>(false);

  const [deletePdfId, setDeletePdfId] = useState<number | null>(null);
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | "">("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePdfClick = () => {
    if (data?.google_storage_file_id) {
      setCurrentPdfUrl(pdfUrl)
      setFlagOpenPdfDialog(true)

    } else if (data.hyperlink) {
      window.open(data.hyperlink, '_blank')
    }
  }


  const handlePdfDelete = async () => {
    const payload: DeleteInterface = {
      marketandsales_pdf_new_id: deletePdfId
    }
    setFlagLoader(true)

    const deleteResponse = await agent.deleteMarketAndSalesSubject.delete(payload)
    if (deleteResponse.status) {
      showSnackbar("Resource deleted successfully!", "success")
      const response = await getMarketAndSalesDetails()
      if (response?.status) {
        if (response?.data && Array.isArray(response.data)) {
          setMarketAndSalesResourceDetails(response.data);
        }
        setFlagLoader(false)
      }
      else {
        const error = response?.data?.error?.message || "Something Went Wrong!"
        showSnackbar(error, "error")
        setFlagLoader(false)
      }

    }
  }

  return (
    <>
      {flagOpenPdfDialog && (
        <GlobalDialog
          flagHeadSection={true}
          maxWidth="xl"
          dialogHead={pdfMainName}
          open={flagOpenPdfDialog}
          view={
            <iframe src={`${currentPdfUrl}#toolbar=0`} width="100%" height="800px" />
          }
          onClose={() => { setFlagOpenPdfDialog(false) }}
        />
      )}

      {flagOpenDeleteDialog && (
        <GlobalDialog
          flagHeadSection={true}
          maxWidth="sm"
          dialogHead="Delete"
          open={flagOpenDeleteDialog}
          view={
            <GlobalWarningDialog
              popupDescription={`Are you sure you want to Delete ${pdfMainName}`}
              primaryButtonText={"Delete"}
              secondaryButtonText={"Cancel"}
              primaryButtonWidth="175px"
              secondaryButtonWidth="175px"
              onDelete={handlePdfDelete}
              handleClose={() => { setFlagOpenDeleteDialog(false) }}
            />
          }
          onClose={() => { setFlagOpenDeleteDialog(false) }}
        />
      )}

      {flagOpenEditDialog &&
        <GlobalDialog
          flagHeadSection={true}
          maxWidth="md"
          dialogHead={"Add New Resource"}
          open={flagOpenEditDialog}
          view={
            <MarketAndSalesResourceDialog
              flag=""
              flagNewResourceDialog={setFlagOpenEditDialog}
              setFlagNewResourceDialog={setFlagOpenEditDialog}
            />
          }
          onClose={() => {
            setFlagOpenEditDialog(false);
          }}
        />
      }


      <Grid container>
        {isBelow1200 ? (
          <>
            <Grid size={{ xs: 12 }} >
              <Grid
                container
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Grid size={{ xs: 3 }} className={`${styles.pdfImgItem}`} >
                  <div style={{ width: "fit-content", cursor: "pointer" }} onClick={handlePdfClick}>
                    {
                      data?.google_storage_file_id ?
                        <Icons iconName='pdf-icon' iconWidth="40px" iconHeight="40px" /> :
                        <Icons iconName='hyperlink-icon' iconWidth="40px" iconHeight="40px" />
                    }
                  </div>
                </Grid>
                <Grid size={{ xs: 7 }} className={`${styles.pdfContentItem}`}>
                  <Grid container>
                    <Grid
                      size={{ xs: 11 }}
                      className={`${styles.pdfNameItem} ${globalStyles.gblOnelineEllipsis}`}
                      sx={{ margin: "5px 0px 5px" }}
                    >
                      <Typography component={"span"}>{pdfMainName}</Typography>
                    </Grid>
                    <Grid size={{ xs: 1 }} className={`${styles.pdfMoreIcon}`}>
                      <IconButton sx={{ padding: "0px", alignItems: "flex-start" }} onClick={handleClick}>
                        <MoreVertIcon sx={{ fontSize: "16px", color: "black" }} />
                      </IconButton>

                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        slotProps={{
                          paper: {
                            style: {
                              width: '100px',
                            },
                          },
                        }}
                      >
                        <MenuItem onClick={() => {
                          setFlagOpenEditDialog(true)
                        }} >Edit</MenuItem>

                        <MenuItem onClick={() => {
                          setFlagOpenDeleteDialog(true)
                          setDeletePdfId(data.marketandsales_pdf_new_id);
                        }} >Delete</MenuItem>

                      </Menu>
                    </Grid>
                    <Grid
                      size={{ xs: 12 }}
                      className={`${styles.pdfSubNameItem} ${globalStyles.gblOnelineEllipsis}`}
                    >
                      <Typography component={"span"}>{pdfSubName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid size={{ xs: 12 }} >
              <Grid container>
                <Grid size={{ xs: 8 }} >
                  <div style={{ width: "fit-content", cursor: "pointer" }} onClick={handlePdfClick}>
                    {
                      data?.google_storage_file_id ?
                        <Icons iconName='pdf-icon' iconWidth="50px" iconHeight="50px" /> :
                        <Icons iconName='hyperlink-icon' iconWidth="50px" iconHeight="50px" />
                    }
                  </div>
                </Grid>
                <Grid size={{ xs: 4 }} className={`${styles.pdfMoreIcon}`}>
                  <IconButton sx={{ padding: "0px", alignItems: "flex-start" }} onClick={handleClick}>
                    <MoreVertIcon sx={{ fontSize: "30px", color: "black" }} />
                  </IconButton>

                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    slotProps={{
                      paper: {
                        style: {
                          width: '100px',
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={() => {
                      setFlagOpenEditDialog(true)
                    }} >Edit</MenuItem>

                    <MenuItem onClick={() => {
                      setFlagOpenDeleteDialog(true)
                      setDeletePdfId(data.marketandsales_pdf_new_id);
                    }} >Delete</MenuItem>

                  </Menu>
                </Grid>
                <Grid
                  size={{ xs: 12 }}
                  className={`${styles.pdfNameItem}`}
                  sx={{ margin: "5px 0px 5px" }}
                >
                  <Typography component={"span"} className={`${globalStyles.gblOnelineEllipsis}`}>{pdfMainName}</Typography>
                </Grid>
                <Grid size={{ xs: 12 }} className={`${styles.pdfSubNameItem}`}>
                  <Typography component={"span"} className={`${globalStyles.gblTwolineEllipsis}`}>{pdfSubName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PdfCards;
