import React, { useContext } from 'react'
import globalStyles from "../../Styles/global.module.scss"
import styles from "../../Styles/ComponentStyles/MainPages/Profile.module.scss"

import Grid from '@mui/material/Grid2';
import AppNavbar from '../../Components/AppNavbar/AppNavbar';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import profile from '../../Assets/General/sample-profile-img.png'
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalSymbols from '../../Utils/Layout/GlobalSymbols/GlobalSymbols';
import GlobalAutocomplate from '../../Utils/Layout/GlobalAutocomplate/GlobalAutoComplate';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { acrylicTeam, capitalize } from '../../Utils/Constant/Constant';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';


const Profile = () => {

    const {
        userProfileDetails,
        setUserProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const userName = userProfileDetails?.user?.firstName && userProfileDetails?.user?.lastName && `${capitalize(userProfileDetails?.user?.firstName)} ${capitalize(userProfileDetails?.user?.lastName)}`;
    const userEmail = userProfileDetails?.user?.email

    return (
        <>
            {/* <AppNavbar navbarHeadingName={"Profile"} /> */}
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 0px" }}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} className={`${styles.profileWrapperItem}`}>
                            <Grid container>
                                <Grid size={{ xs: 12 }}>
                                    <Grid container className={`${styles.profileImageContainer}`}>
                                        <Grid className={`${styles.profileImageItem}`}>
                                            <Avatar
                                                src={profile}
                                                sx={{
                                                    width: "100%",
                                                    height: "100px"
                                                }}
                                            />
                                        </Grid>
                                        <Grid className={`${styles.profileContentItem}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "2px 0px" }}>
                                                    <Typography
                                                        component={'span'}
                                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV18} ${globalStyles.gblOnelineEllipsis}`}
                                                        sx={{ display: "block !important" }}
                                                    >
                                                        {userName || ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} sx={{ margin: "2px 0px" }}>
                                                    <Typography
                                                        component={'span'}
                                                        className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15} ${globalStyles.gblOnelineEllipsis}`}
                                                        sx={{ display: "block !important" }}
                                                    >
                                                        {userEmail || ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid size={{ xs: 12 }} sx={{ margin: "30px 0px 25px" }}>
                                    <Grid container sx={{ columnGap: "25px", rowGap: "25px" }}>
                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Username <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter User Name'
                                                        size='medium'
                                                        fullWidth
                                                        value={userProfileDetails?.user?.userName ? userProfileDetails?.user?.userName : ""}
                                                        onChange={(event) => {
                                                            setUserProfileDetails({
                                                                ...userProfileDetails,
                                                                user: {
                                                                    ...userProfileDetails.user,
                                                                    userName: event.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Email Address <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Email Address'
                                                        size='medium'
                                                        fullWidth
                                                        disabled={true}
                                                        value={userProfileDetails?.user?.email ? userProfileDetails?.user?.email : ""}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        First Name <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter First Name'
                                                        size='medium'
                                                        fullWidth
                                                        value={userProfileDetails?.user?.firstName ? userProfileDetails?.user?.firstName : ""}
                                                        onChange={(event) => {
                                                            setUserProfileDetails({
                                                                ...userProfileDetails,
                                                                user: {
                                                                    ...userProfileDetails.user,
                                                                    firstName: event.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Last Name <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Last Name'
                                                        size='medium'
                                                        fullWidth
                                                        value={userProfileDetails?.user?.lastName ? userProfileDetails?.user?.lastName : ""}
                                                        onChange={(event) => {
                                                            setUserProfileDetails({
                                                                ...userProfileDetails,
                                                                user: {
                                                                    ...userProfileDetails.user,
                                                                    lastName: event.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Agency Name <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Agency Name'
                                                        size='medium'
                                                        fullWidth
                                                        value={userProfileDetails?.user?.agencyName ? userProfileDetails?.user?.agencyName : ""}
                                                        onChange={(event) => {
                                                            setUserProfileDetails({
                                                                ...userProfileDetails,
                                                                user: {
                                                                    ...userProfileDetails.user,
                                                                    agencyName: event.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Phone Number <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Phone Number'
                                                        size='medium'
                                                        fullWidth
                                                        value={userProfileDetails?.user?.contact ? userProfileDetails?.user?.contact : ""}
                                                        onChange={(event) => {
                                                            setUserProfileDetails({
                                                                ...userProfileDetails,
                                                                user: {
                                                                    ...userProfileDetails.user,
                                                                    contact: event.target.value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Address Line 1
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Adresss'
                                                        size='medium'
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Address Line 2
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Adresss'
                                                        size='medium'
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Zipcode
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter Zipcode'
                                                        size='medium'
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        City
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalTextfield
                                                        autoComplete='off'
                                                        placeholder='Enter City'
                                                        size='medium'
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid className={`${styles.gblProfileTextfieldItem}`}>
                                            <Grid container>
                                                <Grid size={12} className={`${globalStyles.gblFieldSpcaing}`}>
                                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                        Select State  <GlobalSymbols symbol={"astric"} />
                                                    </Typography>
                                                </Grid>
                                                <Grid size={12}>
                                                    <GlobalAutocomplate
                                                        placeholder='Enter Agency Name'
                                                        size='small'
                                                        fullWidth
                                                        options={[
                                                            {
                                                                label: "Arizona",
                                                                value: 1
                                                            },
                                                            {
                                                                label: "Alaska",
                                                                value: 2
                                                            },
                                                        ]}
                                                        value={null}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <GlobalButton
                                        value='Update Profile'
                                        variant='contained'
                                        size='large'
                                        className={`${styles.updateProfileBtn}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12 }} className={`${styles.profileWrapperItem}`} sx={{ margin: "15px 0px" }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ mb: "15px" }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV18}`}>
                                        Acrylic Team
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TableContainer sx={{ maxHeight: "40vh", overflow: "auto" }}>
                                        <Table size='small' className={`${globalStyles.gblTableStyles}`}>
                                            <TableHead className={`${globalStyles.gblTableHeadStyles}`}>
                                                <TableRow>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableHeadCell}`}
                                                        sx={{ fontSize: "14px", borderTopLeftRadius: "5px" }}
                                                    >
                                                        IMAGE
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableHeadCell}`}
                                                        sx={{ fontSize: "14px" }}
                                                    >
                                                        NAME
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableHeadCell} `}
                                                        sx={{ fontSize: "14px" }}
                                                    >
                                                        TITLE
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableHeadCell} `}
                                                        sx={{ fontSize: "14px" }}
                                                    >
                                                        EMAIL
                                                    </TableCell>
                                                    <TableCell
                                                        className={`${globalStyles.gblTableHeadCell}`}
                                                        sx={{ fontSize: "14px", borderTopRightRadius: "5px" }}
                                                    >
                                                        PHONE
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {acrylicTeam && acrylicTeam.length > 0 ? (
                                                    acrylicTeam.map((row: any, index: number) => (
                                                        <TableRow
                                                            key={index}
                                                            className={`${globalStyles.gblTableBodyRow}`}
                                                        >
                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText}`}
                                                            >
                                                                {row.image}
                                                            </TableCell>

                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText}`}
                                                            >
                                                                {row.name}
                                                            </TableCell>

                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText}`}
                                                            >
                                                                {row.title}
                                                            </TableCell>

                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText}`}
                                                            >
                                                                {row.email}
                                                            </TableCell>

                                                            <TableCell
                                                                className={`${globalStyles.gblTableCellText}`}
                                                            >
                                                                {row.phone}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={5}
                                                            className={`${globalStyles.gblTableCellText}`}
                                                            align="center"
                                                            sx={{ backgroundColor: "#394457", color: "#ffffff" }}
                                                        >
                                                            No data found
                                                        </TableCell>
                                                    </TableRow>
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Profile