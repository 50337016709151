import React, { useContext, useEffect, useState } from 'react'
import styles from '../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss'
import globalStyles from '../../Styles/global.module.scss'
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Popover,
    useMediaQuery,
    Tooltip,
    Typography,
    Divider,
    AppBar,
    Toolbar
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext'
import Icons from '../../Utils/Layout/Icons/Icons'
import macLogo from '../../Assets/SideNavigationBar/mac-logo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import drawerIcon from '../../Assets/SideNavigationBar/drawerIcon.svg'
import minMacLogo from '../../Assets/SideNavigationBar/minimizelogo.png'
import profile from '../../Assets/General/profile.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../Assets/Logos/login-mac-logo.png"
import { capitalize, useIsBelow1200 } from '../../Utils/Constant/Constant';
import ProfileMenu from './ProfileMenuComponent/ProfileMenu';
import ModuleList from './ProfileMenuComponent/ModuleList';
import agent from '../../Services/api';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';


interface MenuItem {
    name: string;
    icon: React.ReactNode;
    selectedIcon?: React.ReactNode;
    path?: string;
    subItems?: MenuItem[];
    roles: string[];
    onClick?: () => void;
}

interface sideNavProps {
    mobileOpen?: boolean
    setMobileOpen?: any;
    handleDrawerClose: any;
}

const SideNavigationPanel = (props: sideNavProps) => {

    const {
        drawerOpen,
        userProfileDetails,
        setDrawerOpen,
        currentSelectedTab,
        setCurrentSelectedTab,
        setIsAdminNavbarVisible,
        setUserProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const { showSnackbar } = useSnackbar();

    const {
        mobileOpen,
        setMobileOpen,
        handleDrawerClose
    } = props


    const {
        flagLoader,
        setFlagLoader,
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isBelow1200 = useIsBelow1200();
    const isProfileAdmin = userProfileDetails?.user?.flagAdmin
    const userName = userProfileDetails?.user?.firstName && userProfileDetails?.user?.lastName && `${capitalize(userProfileDetails?.user?.firstName)} ${capitalize(userProfileDetails?.user?.lastName)}`;
    const userEmail = userProfileDetails?.user?.email

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorElMob, setAnchorElMob] = React.useState(null);
    const openMob = Boolean(anchorElMob);

    useEffect(() => {
        // Logic to determine which tab should be highlighted based on the pathname
        if (pathname.toLowerCase() === '/dashboard') {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
        else if (pathname.toLowerCase() === '/dashboard/marketing-and-sales') {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
        else if (pathname.toLowerCase().includes('/member')) {
            setCurrentSelectedTab('Member');
        }
        else if (pathname.toLowerCase().includes('/member/user')) {
            setCurrentSelectedTab('Member');
        }
        else if (pathname.toLowerCase().includes('/dashboard/revenue')) {
            setCurrentSelectedTab('Revenue App');
        }
        else if (pathname.toLowerCase() === '/leaderboard') {
            setCurrentSelectedTab('Leaderboard');
        }
        else if (pathname.toLowerCase() === '/plan401kfee') {
            setCurrentSelectedTab('Report');
        }
        else if (pathname.toLowerCase().includes('/profile')) {
            setCurrentSelectedTab('Profile');
        }
        else {
            setCurrentSelectedTab('Dashboard');
            setIsAdminNavbarVisible(true)
        }
    }, [pathname]);

    const menuItems: MenuItem[] = [
        {
            name: 'Dashboard',
            icon: <Icons iconName='dashboard' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='dashboard' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/dashboard',
            roles: ['user', 'admin'], // Accessible by both User and Admin
        },
        {
            name: 'Member',
            icon: <Icons iconName='member' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='member' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/member',
            roles: ['admin'], // Only accessible by Admin
        },
        {
            name: 'Revenue App',
            icon: <Icons iconName='moneyIcon' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='moneyIcon' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/dashboard/revenue',
            roles: ['user', 'admin'], // Only accessible by Admin
        },
        {
            name: 'Leaderboard',
            icon: <Icons iconName='leaderboard' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='leaderboard' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
            path: '/leaderboard',
            roles: ['admin'], // Only accessible by Admin
        },
        {
            name: 'Report',
            icon: <Icons iconName='result401kIcon' iconWidth="22px" iconHeight="22px" />,
            selectedIcon: <Icons iconName='result401kIcon' iconColor="#1A2537" iconWidth="22px" iconHeight="22px" />,
            path: '/plan401kfee',
            roles: ['admin'], // Only accessible by Admin
        },
    ];



    const handleClose = async () => {
        setAnchorEl(null)
    };

    const handleNavigate = (path: any) => {
        setCurrentSelectedTab(path.split('/').pop()); // Extracts the last part of the path to use as the key
        navigate(path);
        setTimeout(() => {
            handleDrawerClose();
        }, 300);
    };

    const handleLogoutUser = async () => {
        setFlagLoader(true)
        const response = await agent.logoutAPI.get()
        console.log("respose of logout api", response.data);
        if (response.status) {
            const responseMesg = response?.data?.message ? response?.data?.message : "You are Logged out Successfully!"
            showSnackbar(responseMesg, "success");
            setUserProfileDetails({
                ...userProfileDetails,
                flagLoggedIn: false,
            });
            setTimeout(() => {
                navigate("/login");
            }, 500);
            setFlagLoader(false)
        }
        else {
            showSnackbar("Somthing Went Wrong!", "error");
            setFlagLoader(false)
        }
    }

    const profileMenu: MenuItem[] = [{
        name: 'Profile',
        icon: <Icons iconName='profile' iconWidth="22px" iconHeight="22px" />,
        selectedIcon: <Icons iconName='profile' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
        path: '/profile',
        roles: ['user', 'admin'],

    }, {
        name: 'Logout',
        icon: <Icons iconName='logout' iconWidth="28px" iconHeight="28px" />,
        selectedIcon: <Icons iconName='logout' iconColor="#000000" iconWidth="22px" iconHeight="22px" />,
        path: '/profile',
        roles: ['user', 'admin'],
        onClick: handleLogoutUser,
    }]

    return (
        <>
            <Grid container className={`${styles.navigationMainContainer} ${globalStyles.fullHeightStyle}  `}>
                <Grid className={`${globalStyles.fullHeightStyle}`}>
                    <Drawer
                        variant="permanent"
                        className={`${globalStyles.fullHeightStyle}`}
                        sx={{
                            display: { xl: 'block', lg: "block", md: "none", sm: "none", xs: "none" },
                            '& .MuiDrawer-paper': {
                                background: "none !important",
                                maxWidth: `${isBelow1200 ? "0px" : drawerOpen ? "252px" : "80px"}`,
                                position: "relative"
                                // top: isAdminNavbarVisible ? "60px" : ""
                            }
                        }}
                        open
                    >
                        <Grid container className={`${styles.navigationWrapper} ${globalStyles.fullHeightStyle}`}>
                            <Grid className={`${globalStyles.fullHeightStyle}`} sx={{ width: "100%" }}>
                                <Grid container className={`${globalStyles.fullHeightStyle}`}>
                                    <Grid size={{ xs: 12 }} className={`${globalStyles.fullHeightStyle}`}>
                                        <Grid container className={`${globalStyles.fullHeightStyle}`}>
                                            <Grid size={{ xs: 12 }} className={`${drawerOpen ? globalStyles.sidenavigationHeightsCloseDrawer : globalStyles.sidenavigationHeights}`}>
                                                <Grid container >
                                                    <Grid size={{ xs: 12 }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '12px',
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            {drawerOpen ? (
                                                                <Grid
                                                                    size={{ xs: 12 }}
                                                                    component="img"
                                                                    className={styles.logo}
                                                                    alt="Logo"
                                                                    src={macLogo}
                                                                />
                                                            ) : (
                                                                <Grid
                                                                    size={{ xs: 12 }}
                                                                    component="img"
                                                                    className={styles.logo}
                                                                    alt="Logo"
                                                                    src={minMacLogo}
                                                                />
                                                            )}

                                                            {drawerOpen ? (
                                                                <IconButton
                                                                    sx={{ padding: '0px' }}
                                                                    onClick={() => setDrawerOpen(false)}
                                                                    className={styles.drawerCloseIcon}
                                                                >
                                                                    <img src={drawerIcon} alt="Close Drawer" />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton
                                                                    sx={{ padding: '0px' }}
                                                                    onClick={() => setDrawerOpen(true)}
                                                                    className={styles.drawerOpenIcon}
                                                                >
                                                                    <img src={drawerIcon} alt="Open Drawer" />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    </Grid>

                                                    <Grid size={{ xs: 12 }} >
                                                        <Grid container >
                                                            <Grid size={{ xs: 12 }} >
                                                                <ProfileMenu userName={userName} userEmail={userEmail}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid size={{ xs: 12 }} >
                                                        <Grid container >
                                                            <Grid size={{ xs: 12 }} >
                                                                <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                            </Grid>
                                                            {drawerOpen ?
                                                                <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} paddingLeft="20px">
                                                                    <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Main
                                                                    </Typography>
                                                                </Grid> :
                                                                <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} >
                                                                    <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Main
                                                                    </Typography>
                                                                </Grid>
                                                            }

                                                            <Grid size={{ xs: 12 }} >
                                                                <ModuleList
                                                                    menuItems={menuItems}
                                                                    handleNavigate={handleNavigate}
                                                                    isProfileAdmin={isProfileAdmin}
                                                                    currentSelectedTab={currentSelectedTab}
                                                                    drawerOpen={drawerOpen}
                                                                    mobileOpen={mobileOpen}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {/* <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`}>
                                                        <ModuleList
                                                            menuItems={menuItems}
                                                            handleNavigate={handleNavigate}
                                                            isProfileAdmin={isProfileAdmin}
                                                            currentSelectedTab={currentSelectedTab}
                                                            drawerOpen={drawerOpen}
                                                            mobileOpen={mobileOpen}
                                                        />
                                                    </Grid> */}
                                                    {/* <Grid size={{ xs: 12 }} >
                                                        <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} >
                                                        <ModuleList
                                                            menuItems={profileMenu}
                                                            handleNavigate={handleNavigate}
                                                            isProfileAdmin={isProfileAdmin}
                                                            currentSelectedTab={currentSelectedTab}
                                                            drawerOpen={drawerOpen}
                                                            mobileOpen={mobileOpen}
                                                        />
                                                    </Grid> */}
                                                    <Grid size={{ xs: 12 }} >
                                                        <Grid container >
                                                            <Grid size={{ xs: 12 }} >
                                                                <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                            </Grid>
                                                            {drawerOpen ?
                                                                <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} paddingLeft="20px">
                                                                    <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Settings
                                                                    </Typography>
                                                                </Grid> :
                                                                <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} >
                                                                    <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                        Settings
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            <Grid size={{ xs: 12 }} >
                                                                <ModuleList
                                                                    menuItems={profileMenu}
                                                                    handleNavigate={handleNavigate}
                                                                    isProfileAdmin={isProfileAdmin}
                                                                    currentSelectedTab={currentSelectedTab}
                                                                    drawerOpen={drawerOpen}
                                                                    mobileOpen={mobileOpen}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Drawer>

                    <Box
                        aria-label="mailbox folders"
                        sx={{
                            display: { xl: 'none', lg: "none", md: "block", sm: "block", xs: "block" },
                            backgroundColor: "white",
                            margin: "20px 0px"
                        }}
                    >
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={() => handleDrawerClose()}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: 250,
                                    backgroundColor: "#273348"
                                },
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                            }}
                        >
                            <Grid container sx={{ height: "100%" }}>
                                <Grid size={{ xs: 12 }} sx={{ padding: '15px 10px', height: "100%" }}>
                                    <Grid container sx={{ height: "100%" }}>
                                        <Grid size={{ xs: 12 }} className={`${styles.sideNavListAppsContainer}`}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <img src={logo} style={{ width: "100%", height: "auto", objectFit: "fill" }} alt="" />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container >
                                                        <Grid size={{ xs: 12 }} >
                                                            <ProfileMenu userName={userName} userEmail={userEmail}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container >
                                                        <Grid size={{ xs: 12 }} >
                                                            <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                        </Grid>

                                                        <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} >
                                                            <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                Main
                                                            </Typography>
                                                        </Grid>

                                                        <Grid size={{ xs: 12 }} >
                                                            <ModuleList
                                                                menuItems={menuItems}
                                                                handleNavigate={handleNavigate}
                                                                isProfileAdmin={isProfileAdmin}
                                                                currentSelectedTab={currentSelectedTab}
                                                                drawerOpen={drawerOpen}
                                                                mobileOpen={mobileOpen}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid size={{ xs: 12 }} sx={{ m: "5px 0px" }}>
                                                    <Divider sx={{ borderColor: "#ffffff", width: "100%" }} />
                                                </Grid>
                                                <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`}>
                                                    <ModuleList
                                                        menuItems={menuItems}
                                                        handleNavigate={handleNavigate}
                                                        isProfileAdmin={isProfileAdmin}
                                                        currentSelectedTab={currentSelectedTab}
                                                        drawerOpen={drawerOpen}
                                                        mobileOpen={mobileOpen}
                                                    />
                                                </Grid> */}
                                                {/* <Grid size={{ xs: 12 }} >
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Typography component='span'>
                                                                Settings
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`}>
                                                            <ModuleList
                                                                menuItems={profileMenu}
                                                                handleNavigate={handleNavigate}
                                                                isProfileAdmin={isProfileAdmin}
                                                                currentSelectedTab={currentSelectedTab}
                                                                drawerOpen={drawerOpen}
                                                                mobileOpen={mobileOpen}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                                <Grid size={{ xs: 12 }} >
                                                    <Grid container >
                                                        <Grid size={{ xs: 12 }} >
                                                            <Divider sx={{ border: "0.75px solid #CBCBCB" }} />
                                                        </Grid>

                                                        <Grid size={{ xs: 12 }} className={`${styles.sideNavigationMainItem}`} >
                                                            <Typography component='span' className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV15}`}>
                                                                Settings
                                                            </Typography>
                                                        </Grid>

                                                        <Grid size={{ xs: 12 }} >
                                                            <ModuleList
                                                                menuItems={profileMenu}
                                                                handleNavigate={handleNavigate}
                                                                isProfileAdmin={isProfileAdmin}
                                                                currentSelectedTab={currentSelectedTab}
                                                                drawerOpen={drawerOpen}
                                                                mobileOpen={mobileOpen}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Drawer>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}


export default SideNavigationPanel