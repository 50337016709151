import React, { useContext } from 'react'
import styles from "../../../Styles/ComponentStyles/GlobalComponentStyles/SideNavigationPanel.module.scss"
import globalStyles from "../../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Avatar, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GlobalUserContext, GlobalUserContextPayload } from '../../../Context/GlobalUserDetailContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import profile from '../../../Assets/General/profile.png'


const ProfileMenu = (props: any) => {

    const {
        drawerOpen,
        userProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const isProfileAdmin = userProfileDetails?.user?.flagAdmin

    const {
        userName,
        userEmail,
        anchorEl,
        handleClose,
        setAnchorEl,
        open,
        mobileOpen,
        anchorElMob,
        openMob,
        setAnchorElMob,
        handleLogoutUser
    } = props

    const navigate = useNavigate()

    return (
        <>
            <Grid size={{ xs: 12 }} sx={{ padding: drawerOpen ? '0px' : '0px 5px' }}>
                {mobileOpen ?
                    (<>
                        <Grid className={`${globalStyles.sideNavbarProfileItem}`}>
                            <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                <Grid size={{ xs: 2 }} className={`${globalStyles.sideNavImg}`}>
                                    <Avatar
                                        src={profile}
                                        sx={{
                                            width: "30px",
                                            height: "30px"
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ xs: "grow" }} className={`${globalStyles.sideNavAdminItem}`}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}>
                                                {userName ? userName : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid size={{ xs: 12 }}>
                                            <Typography component={'span'} className={`${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}>
                                                {userEmail ? userEmail : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </>) :
                    (<>
                        <Grid >
                            <Grid container className={`${globalStyles.sideNavProfileContainer}`}>

                                {!drawerOpen ? (<><Grid size={{ xs: drawerOpen ? 2 : 7.5 }} className={`${globalStyles.sideNavImg}`}>
                                    <Avatar
                                        src={profile}
                                        sx={{
                                            width: drawerOpen ? "100%" : "30px",
                                            height: drawerOpen ? "100%" : "30px",
                                            marginLeft: "10px"
                                        }}
                                    />
                                </Grid></>) : (<>
                                    <Grid size={{ xs: drawerOpen ? 2 : 7.5 }} className={`${globalStyles.sideNavImg}`}>
                                        <Avatar
                                            src={profile}
                                            sx={{
                                                width: drawerOpen ? "100%" : "30px",
                                                height: drawerOpen ? "100%" : "30px"
                                            }}
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 9 }} className={`${globalStyles.sideNavAdminItem}`}>
                                        <Grid container>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16} ${styles.ellipsisStyles}`}
                                                >
                                                    {userName ? userName : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                >
                                                    {userEmail ? userEmail : ""}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>)
                                }

                                {/* 
                                {!drawerOpen &&
                                    <Grid container sx={{ padding: "0px 10px" }}>
                                        <Grid size={{ xs: 12 }}>
                                            <Grid container className={`${globalStyles.sideNavProfileContainer}`}>
                                                <Grid size={{ xs: 2 }} className={`${globalStyles.sideNavImg}`}>
                                                    <Avatar
                                                        src={profile}
                                                        sx={{
                                                            width: "30px",
                                                            height: "30px"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 9.8 }} className={`${globalStyles.sideNavAdminItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16} ${styles.ellipsisStyles}`}
                                                            >
                                                                {userName ? userName : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV14} ${styles.ellipsisStyles}`}
                                                            >
                                                                {userEmail ? userEmail : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                            <Divider sx={{ borderColor: "#D1D1D1" }} />
                                        </Grid>
                                    </Grid>
                                } */}

                            </Grid>
                        </Grid>
                    </>)
                }
            </Grid >
        </>
    )
}

export default ProfileMenu