import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import styles from "../../../Styles/ComponentStyles/MainPages/dashboard.module.scss";
import globalStyles from "../../../Styles/global.module.scss";
import AppNavbar from "../../../Components/AppNavbar/AppNavbar";
import DashboardNavbar from "../DashboardNavbar/DashboardNavbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { pdfData } from "../../../Utils/Constant/Constant";
import PdfCards from "../../../Components/PdfCards/PdfCards";
import { useNavigate } from "react-router-dom";
import NewResourceDialog from "../../../Components/Dialogs/NewResourceDialog";
import GlobalTextfield from "../../../Utils/Layout/GlobalTextfield/GlobalTextfield";
import GlobalWarningDialog from "../../../Utils/Layout/GlobalWarningDialog/GlobalWarningDialog";
import GlobalDialog from "../../../Utils/Layout/GlobalDialog/GlobalDialog";
import GlobalButton from "../../../Utils/Layout/GlobalButton/GlobalButton";
import dashboardStyles from "../../../Styles/ComponentStyles/MainPages/dashboard.module.scss";
import { DashboardContext, DashboardDetailPayload } from "../../../Context/DashboardDetailContext";
import { MarketAndSalesResourcePayloadDataModel, MarketAndSalesResourcesResponseModel } from "../../../models/dashboardAxiosModels";
import MarketAndSalesResourceDialog from "../../../Components/Dialogs/MarketAndSalesResourceDialog";
import { useSnackbar } from "../../../Utils/Layout/GlobalSnackbar/GlobalSnackbar";
import { GlobalUserContext, GlobalUserContextPayload } from "../../../Context/GlobalUserDetailContext";
import agent from "../../../Services/api";

interface PdfData {
  img: string;
  pdfHeadName: string;
  pdfSubHeadName: string;
}

interface AccordionData {
  accordianName: string;
  accordianData: PdfData[];
}


const DashboardMarketingAndSalesResources = () => {

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const {
    setFlagLoader,
  } = useContext(GlobalUserContext) as GlobalUserContextPayload

  const {
    marketAndSalesResourceDetails,
    setMarketAndSalesResourceDetails,
    getMarketAndSalesDetails,

    marketingAndSalesResorcePayloadDetails,
    setMarketingAndSalesResorcePayloadDetails,

  } = useContext(DashboardContext) as DashboardDetailPayload


  const [expanded, setExpanded] = useState<string | false>(false);
  const [flag, setFlag] = useState<string | "">("");
  const [editing, setEditing] = useState<string | "">("");

  const [categoryName, setCategoryName] = useState<string | "">("");
  const [previousCategoryName, setPreviousCategoryName] = useState<string | "">("");
  const [flagDeleteResourceDialog, setFlagDeleteResourceDialog] = useState<boolean>(false);
  const [flagNewResourceDialog, setFlagNewResourceDialog] = useState<boolean>(false);
  const [storedDeleteId, setStoredDeleteId] = useState<any>({
    category_name: "",
    category_id: null,
    marketandsales_pdf_new_id: null,
  })


  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = () => {
    navigate("/");
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };



  useEffect(() => {
    const fetchData = async () => {
      const response = await getMarketAndSalesDetails();
      console.log("response of getMarketAndSalesDetails", response);
      setFlagLoader(true)
      if (response?.status) {
        if (response?.data && Array.isArray(response.data)) {
          setMarketAndSalesResourceDetails(response.data);
        }
        setFlagLoader(false)
      }
      else {
        const error = response?.data?.error?.message || "Something Went Wrong!"
        setMarketAndSalesResourceDetails([]);
        showSnackbar(error, "error")
        setFlagLoader(false)
      }
    };

    fetchData();
  }, []);


  const handleEditResource = async () => {

    if (categoryName === previousCategoryName) {
      showSnackbar("No changes made. Category Name same as previous", "error");
      return;
    }

    const payload: MarketAndSalesResourcePayloadDataModel = {
      categoryName: marketingAndSalesResorcePayloadDetails?.categoryName ?? "",
      categoryID: marketingAndSalesResorcePayloadDetails?.categoryID ?? null,
      description: "",
      hyperlink: null,
      hyperlink_name: null,
      google_storage_file_id: null,
      google_storage_thumbnail_id: null
    }
    setFlagLoader(true)
    const response = await agent.marketAndSalesResourceUpdateAPI.put(payload)
    if (response?.status && response?.data) {
      const response = await getMarketAndSalesDetails()
      showSnackbar("Edited Resource Successfully", "success")
      if (response?.status) {
        if (response?.data && Array.isArray(response.data)) {
          setMarketAndSalesResourceDetails(response.data);
        }
        setFlagLoader(false)
      }
      else {
        const error = response?.data?.error?.message || "Something Went Wrong!"
        setMarketAndSalesResourceDetails([]);
        showSnackbar(error, "error")
        setFlagLoader(false)
      }
    } else {
      const error = response?.data?.error?.message || "Something Went Wrong!"
      showSnackbar(error, "error")
      setFlagLoader(false)
    }
  }


  const handleDeleteResource = async () => {

    const payload: any = {
      marketandsales_pdf_new_id: storedDeleteId?.marketandsales_pdf_new_id,
      category_id: storedDeleteId?.category_id
    }
    setFlagLoader(true)
    const deleteResponse = await agent.deleteMarketAndSalesSubject.delete(payload)
    if (deleteResponse.status) {
      showSnackbar("Resource deleted successfully!", "success")
      const response = await getMarketAndSalesDetails();
      if (response?.status) {
        if (response?.data && Array.isArray(response.data)) {
          setMarketAndSalesResourceDetails(response.data);
          setFlagDeleteResourceDialog(false)
        }
        setFlagLoader(false)
      }
      else {
        const error = response?.data?.error?.message || "Something Went Wrong!"
        setMarketAndSalesResourceDetails([]);
        showSnackbar(error, "error")
        setFlagLoader(false)
        setFlagDeleteResourceDialog(false)
      }

    }
  }

  return (
    <>
      <DashboardNavbar
        onClick={handleClick}
        headName={"Marketing And Sales Resources"}
        children={
          <Grid>
            <GlobalButton
              value="New Resource"
              className={`${globalStyles.globleButtonStyleV2}`}
              startIcon={<AddIcon />}
              onClick={() => {
                setFlag("add-new-resource")
                setFlagNewResourceDialog(true);
              }}
            />
          </Grid>
        }
      />

      <GlobalDialog
        flagHeadSection={true}
        maxWidth="sm"
        dialogHead={"Delete Confirmation"}
        open={flagDeleteResourceDialog}
        view={
          <GlobalWarningDialog
            popupDescription={`Are you sure you want delete ${storedDeleteId?.category_name ? storedDeleteId?.category_name : ""}`}
            primaryButtonText={"Delete"}
            secondaryButtonText={"Cancel"}
            primaryButtonWidth="175px"
            secondaryButtonWidth="175px"
            onDelete={handleDeleteResource}
            handleClose={() => {
              setFlagDeleteResourceDialog(false);
            }}
          />
        }
        onClose={() => {
          setFlagDeleteResourceDialog(false);
        }}
      />

      <GlobalDialog
        flagHeadSection={true}
        maxWidth="md"
        dialogHead={"Add New Resource"}
        open={flagNewResourceDialog}
        view={
          <MarketAndSalesResourceDialog
            flag={flag}
            flagNewResourceDialog={flagNewResourceDialog}
            setFlagNewResourceDialog={setFlagNewResourceDialog}
          />
        }
        onClose={() => {
          setFlagNewResourceDialog(false);
        }}
      />



      <Grid container className={`${styles.dsMarketingContainer}`}>
        <Grid size={{ xs: 12 }} className={`${styles.dsMarketing}`}>
          <Grid container>
            <Grid size={{ xs: 12 }}></Grid>

            <Grid size={{ xs: 12 }}>
              {marketAndSalesResourceDetails && marketAndSalesResourceDetails?.map((resource: MarketAndSalesResourcesResponseModel) => {
                return (
                  <Accordion
                    key={resource.category_id}
                    expanded={expanded == resource.category_name}
                    onChange={handleChange(resource.category_name)}
                    className={`${styles.dsGlobalFeatureCardStyle}`}
                    sx={{ padding: "0px !important" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid container sx={{ width: "100%", alignItems: "center", justifyContent: "space-between", }}>
                        <Grid width="60%">
                          {editing === resource.category_name ? (
                            <GlobalTextfield
                              value={categoryName}
                              onChange={(e) => {
                                setCategoryName(e.target.value);
                                setMarketingAndSalesResorcePayloadDetails((prev: MarketAndSalesResourcePayloadDataModel | null) => {
                                  return prev ? { ...prev, categoryID: resource.category_id, categoryName: e.target.value } : null
                                })
                              }}

                              fullWidth
                              size="medium"
                              autoComplete="off"

                              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                if (e.key === 'Enter') {
                                  handleEditResource();
                                }
                              }}

                              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => { handleEditResource() }}
                            />
                          ) : (
                            <Typography className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16}`}>
                              {resource.category_name}
                            </Typography>
                          )}
                        </Grid>

                        {/* --Operation buttons-- */}
                        <Grid>
                          <Grid container sx={{ columnGap: "14px", paddingRight: "14px" }} onClick={handleButtonClick}>

                            {editing !== resource.category_name &&
                              <>
                                <Grid>
                                  <IconButton
                                    sx={{
                                      borderRadius: "6px",
                                      boxShadow: "0px 0px 2px 0px #FFFFFF80",
                                    }}
                                    onClick={() => {
                                      setFlag("")
                                      setFlagNewResourceDialog(true);
                                      setMarketingAndSalesResorcePayloadDetails((prev: MarketAndSalesResourcePayloadDataModel | null) => {
                                        return prev ? { ...prev, categoryID: resource.category_id } : null
                                      })
                                    }}
                                  >
                                    <AddIcon className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV20}`} />
                                  </IconButton>
                                </Grid>

                                <Grid>
                                  <IconButton
                                    sx={{
                                      borderRadius: "6px",
                                      boxShadow: "0px 0px 2px 0px #FFFFFF80",
                                    }}
                                    onClick={() => {
                                      setCategoryName(resource.category_name);
                                      setPreviousCategoryName(resource.category_name)
                                      setEditing(resource.category_name); // enable edit mode
                                    }}
                                  >
                                    <EditIcon className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV20}`} />
                                  </IconButton>
                                </Grid>

                                <Grid>
                                  <IconButton
                                    sx={{
                                      borderRadius: "6px",
                                      boxShadow: "0px 0px 2px 0px #FFFFFF80",
                                    }}
                                    onClick={() => {
                                      setFlagDeleteResourceDialog(true)
                                      setStoredDeleteId({
                                        category_name: resource?.category_name,
                                        category_id: resource?.category_id,
                                        marketandsales_pdf_new_id: null
                                      })
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV20}`} />
                                  </IconButton>
                                </Grid>
                              </>
                            }

                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid size={{ xs: 12 }}>
                        <Grid container sx={{ gap: "20px" }}>
                          {resource && resource?.resources?.length > 0 && resource?.resources?.map((data) => {
                            const pdfMainHeading = data?.file?.google_storage_original_file_name
                            const pdfDiscription = data?.pdf_description
                            const pdfUrl = data?.file?.google_storage_public_url
                            return (
                              <>
                                <Grid className={`${styles.pdfCarditem}`}>
                                  <PdfCards
                                    pdfMainName={pdfMainHeading}
                                    pdfSubName={pdfDiscription}
                                    pdfUrl={pdfUrl}
                                    data={data}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>


    </>
  );
};

export default DashboardMarketingAndSalesResources;
