import React from 'react'
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Divider, Typography } from '@mui/material';


interface ComplianceDocumentProps {
    policyBg?: string
}

const ComplianceDocument = (props: ComplianceDocumentProps) => {

    const { policyBg } = props

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }} sx={{ backgroundColor: policyBg ? policyBg : "#ECECEC" }} className={`${globalStyles.cdMainItem}`}>
                    <Grid container>
                        <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 5px" }}>
                            <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV24}`}>
                                Compliance Documents
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px 20px" }}>
                            <Typography
                                component={'span'}
                                className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={() => window.open("https://drive.google.com/file/d/1KZUKAFvCxMN980rs8XEM0fc0UgUk6_KR/view?usp=sharing", "_blank")}
                            >
                                ADV-Part 2A Brochure

                            </Typography>
                            <span>&nbsp;|&nbsp;</span>
                            <Typography
                                component={'span'}
                                className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={() => window.open("https://drive.google.com/file/d/14SpCG76zZ-KNmS0biZK2T1-Dqbg5t7GP/view?usp=sharing", "_blank")}
                            >
                                CRS Form
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Divider sx={{ borderColor: "#000000" }} />
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 5px" }}>
                            <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}>
                                Disclosure
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px" }}>
                            <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}>
                                WARRANTIES & DISCLAIMERS
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px" }}>
                            <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}>
                                There are no warranties implied.
                            </Typography>
                        </Grid>

                        <Grid size={{ xl: 10, lg: 11, md: 11, sm: 11, xs: 11 }} sx={{ margin: "5px auto" }}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px" }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV15}`}>
                                        Acrylic® Financial, Inc. (“RIA Firm”) is a registered investment adviser located in Fountain Hills, AZ . Acrylic® Financial, Inc.
                                        may only transact business in those states in which it is registered, or qualifies for an exemption or exclusion from registration requirements.
                                        Acrylic® Financial, Inc.’s web site is limited to the dissemination of general information pertaining to its advisory services,
                                        together with access to additional investment-related information, publications, and links. Accordingly, the publication of Acrylic® Financial,
                                        Inc.’s web site on the Internet should not be construed by any consumer and/or prospective client as RIA Firm Name’s solicitation to effect,
                                        or attempt to effect transactions in securities, or the rendering of personalized investment advice for compensation, over the Internet.
                                        Any subsequent, direct communication by Acrylic® Financial, Inc. with a prospective client shall be conducted by a representative that is either
                                        registered or qualifies for an exemption or exclusion from registration in the state where the prospective client resides. For information
                                        pertaining to the registration status of Acrylic® Financial, Inc., please contact the state securities regulators for those states in which
                                        Acrylic® Financial, Inc. maintains a registration filing. A copy of Acrylic® Financial, Inc.’s current written disclosure statement discussing
                                        RIA Firm Name’s business operations, services, and fees is available at the SEC’s investment adviser public information
                                        website – www.adviserinfo.sec.gov or from Acrylic® Financial, Inc. upon written request. Acrylic® Financial, Inc. does not make any representations or
                                        warranties as to the accuracy, timeliness, suitability, completeness, or relevance of any information prepared by any unaffiliated third party,
                                        whether linked to Acrylic® Financial, Inc.’s web site or incorporated herein, and takes no responsibility therefor. All such information is provided
                                        solely for convenience purposes only and all users thereof should be guided accordingly.
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px" }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV15}`}>
                                        This website and information are provided for guidance and information purposes only.
                                        Investments involve risk and unless otherwise stated, are not guaranteed.
                                        Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy.
                                        This website and information are not intended to provide investment, tax, or legal advice.
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px" }}>
                                    <Typography component={'span'} className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV15}`}>
                                        Insurance products and services are offered and sold through Brokers Alliance, Inc. and individually licensed and appointed insurance agents.
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }} sx={{ margin: "5px 0px" }}>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={() => window.open("https://acrylicfinancial.com/terms", "_self")}
                                    >
                                        Terms and Conditions
                                    </Typography>&nbsp;|&nbsp;
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={() => window.open("https://acrylicfinancial.com/privacy", "_self")}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 5px" }}>
                                    <Typography
                                        component={'span'}
                                        className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                    >
                                        Copyright {new Date().getFullYear()}&nbsp;
                                        <Typography
                                            component={"span"}
                                            className={`${globalStyles.gblCBlack} ${globalStyles.globalTextV16}`}
                                            onClick={() => window.open("https://acrylicfinancial.com/", "_self")}
                                            sx={{
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            Acrylic® Financial
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ComplianceDocument