import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import pdf from '../../Assets/Dashboard/pdf.png'

export const useIsBelow1200 = () => {
    return useMediaQuery('(max-width : 1200px) and (min-width : 0px)');
};
export const useIsBelow900 = () => {
    return useMediaQuery('(max-width : 900px) and (min-width : 0px)');
};
export const useIsBelow600 = () => {
    return useMediaQuery('(max-width : 600px) and (min-width : 0px)');
};
export const useIsBelow400 = () => {
    return useMediaQuery('(max-width : 400px) and (min-width : 0px)');
};
export const useIsBelow450 = () => {
    return useMediaQuery('(max-width : 450px) and (min-width : 0px)');
};
export const capitalize = (str: string) => {
    if (!str) return "";
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};


export const baseURL = "https://apidevmac.acrylicfinancial.com/public/assets/images/";



// DASHBOARD
export const tabsConfig = [
    { label: "Information", value: "Information" },
    { label: "Other Apps", value: "OtherApps" },
];

export const keyIndicesTabs = [
    { label: "Indices", value: "Indices" },
    { label: "Commodities", value: "Commodities" },
    { label: "Bonds", value: "Bonds" },
    { label: "Forex", value: "Forex" },
];



export const keyIndicesData = [
    {
        shareName: "SPX500USD",
        value: "6,015.4",
        shareNameType: "S&P 500",
        shareNameTypeValue: "+12.80 +0.21%"
    },
    {
        shareName: "NAS100USD",
        value: "21,209.2",
        shareNameType: "Nasdaq 100",
        shareNameTypeValue: "+12.80 +0.21%"
    },
    {
        shareName: "NAS100USD",
        value: "21,209.2",
        shareNameType: "Nasdaq 100",
        shareNameTypeValue: "+12.80 +0.21%"
    },
    {
        shareName: "NAS100USD",
        value: "21,209.2",
        shareNameType: "Nasdaq 100",
        shareNameTypeValue: "+12.80 +0.21%"
    },


];


export const pdfData = [
    {
        accordianName: "401(k) Materials",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Account Service Forms",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Current Portfolio Backtests",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Errors & Omissions Insurance",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Fee Schedules",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Indexed Universal Life Customizable Flyers",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Market Commentary",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "New Account Forms",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Pre-Approved Marketing pieces",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },
    {
        accordianName: "Training",
        accordianData: [
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "401(k) Initial Meeting (3)",
                pdfSubHeadName: "401(k) Initial Meeting"
            },
            {
                img: pdf,
                pdfHeadName: "Acrylic 401k participant intake (2)",
                pdfSubHeadName: "401(k) Participant Handout"
            },
        ]
    },

]

export const rows = [
    {
        name: 'ADMS',
        email: 100,
        repcode: 100,
        date: '2024-08-01',
        group: "example",
        payoutAmount: "example",
        accounts: "example",
        accountsError: "example",
        billableMv: "example",
        advisorSplitAd: "example",
        exclusion: "example",
        discount: "example",
        cashShortages: "example",
        account401k: "example",
        accountBillableMv: "example",
        paymentAmount: "example",
        totalPayoutAmount: "example",

    },
    {
        name: 'ADMS',
        email: 100,
        repcode: 100,
        date: '2024-08-01',
        group: "example",
        payoutAmount: "example",
        accounts: "example",
        accountsError: "example",
        billableMv: "example",
        advisorSplitAd: "example",
        exclusion: "example",
        discount: "example",
        cashShortages: "example",
        account401k: "example",
        accountBillableMv: "example",
        paymentAmount: "example",
        totalPayoutAmount: "example",
    },
    {
        name: 'ADMS',
        email: 100,
        repcode: 100,
        date: '2024-08-01',
        group: "example",
        payoutAmount: "example",
        accounts: "example",
        accountsError: "example",
        billableMv: "example",
        advisorSplitAd: "example",
        exclusion: "example",
        discount: "example",
        cashShortages: "example",
        account401k: "example",
        accountBillableMv: "example",
        paymentAmount: "example",
        totalPayoutAmount: "example",
    },
]

export const planAssumptionRows = [
    {
        label: "Number of Employees",
        acrylic: "$7",
        highlandSmiles: "$7",
    },
    {
        label: "Set Up Fee",
        acrylic: "$0",
        highlandSmiles: "$0",
    },
    {
        label: "Conversion Fee",
        acrylic: "$0",
        highlandSmiles: "$0",
    },
    {
        label: "Annual Recordkeeping/Admin Fee",
        acrylic: "$1,800",
        highlandSmiles: "$500",
    },
    {
        label: "Per Participant Annual Fee",
        acrylic: "$36",
        highlandSmiles: "$10",
    },
    {
        label: "Other Fee",
        acrylic: "$0",
        highlandSmiles: "$0",
    },
    {
        label: "Total Annual Direct Fees (Paid by the business)",
        acrylic: "$2,152",
        highlandSmiles: "$570",
    },
];

export const annualFeesPlanAssets = [
    {
        label: "Acrylic and 3(38) Advisor fee",
        acrylic: "$7",
        highlandSmiles: "$7",
    },
    {
        label: "Other Assets Based Fee",
        acrylic: "$0",
        highlandSmiles: "$0",
    },
    {
        label: "Average Mutual Fund Expense",
        acrylic: "$0",
        highlandSmiles: "$0",
    },
    {
        label: "Record Keeper Asset Fee (includes custody)",
        acrylic: "$1,800",
        highlandSmiles: "$500",
    },
    {
        label: "Total Annual Investment Related Fees (%)",
        acrylic: "$36",
        highlandSmiles: "$10",
    },
];


export const data = [
    {
        year: "1",
        yearEndingBalance: {
            acrylic: "$970,694",
            highlandSmiles: "$961,936",
        },
        saving: {
            dueToDirectFees: "-$1,582",
            dueToPlanAssetsFees: "$10,340",
            total: "$8,578",
        },
    },
    {
        year: "5",
        yearEndingBalance: {
            acrylic: "$1,520,979",
            highlandSmiles: "$1,457,111",
        },
        saving: {
            dueToDirectFees: "-$9,280",
            dueToPlanAssetsFees: "$73,149",
            total: "$63,868",
        },
    },
    {
        year: "10",
        yearEndingBalance: {
            acrylic: "$2,443,116",
            highlandSmiles: "$2,247,568",
        },
        saving: {
            dueToDirectFees: "-$22,917",
            dueToPlanAssetsFees: "$218,467",
            total: "$195,548",
        },
    },
];
// DASHBOARD


// USER 
export const members = [
    { label: "Users", value: "Users", navigation: "/member/users" },
    // { label: "User Groups", value: "UserGroups", navigation: "/member/usergroups" },
    { label: "Recently Registered User", value: "RecentlyRegisteredUser", navigation: "/member/recently-registered-user" },
    { label: "Add Mac App", value: "AddMacApp", navigation: "/member/mac-app" },
    // { label: "Edit Group", value: "EditGroup", navigation: "/member/edit-group" },
];

export const userData = [
    {
        id: 1,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 2,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 3,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 4,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 5,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
    {
        id: 6,
        userName: "Test User",
        firstName: "Test",
        lastName: "User 1",
        contact: "+ 1 (732) 345-0033",
        agency: "Acrylic Financial Inc",
        email: "test@gmail.com"
    },
]

export const macSampleData = [
    { id: 1, name: 'Full Account Opening/Management', allowAccess: true, defaultInstalled: false },
    { id: 2, name: 'TD Ameritrade Institutional’s Veo platform', allowAccess: false, defaultInstalled: false },
    { id: 3, name: 'Compliance', allowAccess: true, defaultInstalled: false },
    { id: 4, name: 'Client Marketing & Correspondence', allowAccess: false, defaultInstalled: false },
    { id: 5, name: 'Brokersalliance MAC', allowAccess: false, defaultInstalled: false },
    { id: 6, name: 'LifeBrain.io', allowAccess: false, defaultInstalled: false },
    { id: 7, name: 'The Retirement ACE', allowAccess: false, defaultInstalled: false },
    { id: 8, name: 'Portfolio Analysis Request', allowAccess: false, defaultInstalled: false },
    { id: 9, name: 'Licensing & Continuing Education', allowAccess: false, defaultInstalled: false },
    { id: 10, name: 'Revenue App', allowAccess: false, defaultInstalled: false },
    { id: 11, name: 'Marketing and Sales Resources', allowAccess: false, defaultInstalled: false },
];

export const subscribedApps = [
    {
        id: 1,
        name: 'Full Account Opening/Management',
        allowAccess: true,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 2,
        name: 'TD Ameritrade Institutional’s Veo platform',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 3,
        name: 'Compliance',
        allowAccess: true,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 4,
        name: 'Client Marketing & Correspondence',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 5,
        name: 'Brokersalliance MAC',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 6,
        name: 'LifeBrain.io',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 7,
        name: 'The Retirement ACE',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 8,
        name: 'Portfolio Analysis Request',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 9,
        name: 'Licensing & Continuing Education',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 10,
        name: 'Revenue App',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
    {
        id: 11,
        name: 'Marketing and Sales Resources',
        allowAccess: false,
        defaultInstalled: false,
        currentAppStatus: [
            { key: 1, value: "Under Process" },
            { key: 2, value: "Subscribed" }
        ]
    },
];

export const dashboardDetails = {
    user_app: [
        {
            app_type: "",
            app_type_data: [
                {
                    id: 0,
                    app_name: "string",
                    app_shortcode: "string",
                    app_fullname: "string",
                    app_image: "string",
                    featured_image: "string",
                    app_description: "string",
                    allow_access: true,
                    default_installed: true,
                    app_type: { type_id: 0, type_name: "string" },
                    app_status: { status_id: 0, status_name: "string" },
                    app_url: [
                        { app_title: "string", app_url: "string" }
                    ],
                    app_order: 0,
                    installed: true,
                    flag_new_app: true
                },
            ]
        },
    ],
    apps: [
        {
            id: 0,
            app_name: "string",
            app_shortcode: "string",
            app_fullname: "string",
            app_image: "string",
            featured_image: "string",
            app_description: "string",
            allow_access: true,
            default_installed: true,
            app_type: { type_id: 0, type_name: "string" },
            app_status: { status_id: 0, status_name: "string" },
            app_url: [
                { app_title: "string", app_url: "string" }
            ],
            app_order: 0,
            installed: true,
            flag_new_app: true
        }
    ],
}

export const marketingReview = [
    {
        subject: "test 1",
        time: "Thu, 9 july 2024 6:30:00 GMT"
    },
    {
        subject: "test 2",
        time: "Thu, 9 july 2024 6:30:00 GMT"
    },
    {
        subject: "test 3",
        time: "Thu, 9 july 2024 6:30:00 GMT"
    },
    {
        subject: "test 4",
        time: "Thu, 9 july 2024 6:30:00 GMT"
    },
]

export const genericRevenueAppData = {
    include: [
        {
            key: 1,
            value: "Yes",
        },
        {
            key: 2,
            value: "No",
        },

        "Yes", "No"],
    group: [
        {
            id: 1,
            groupName: "Brook Place Securities, Inc",
        },
        {
            id: 2,
            groupName: "Group Place Securities, Inc",
        },
    ],
}

export const revenueAppData = [
    {
        userName: "Test User 1",
        email: "testuser@gmail.com",
        repcode: "YKQ",
        include: "",
        groupValue: "",
        payoutAmount: "",
        accounts: "",
        accountInError: "",
        billableMv: "",
        splitAdjustment: "",
        exclusions: "",
        discounts: "",
        cashShortages: ""
    },
    {
        userName: "Test User 2",
        email: "testuser@gmail.com",
        repcode: "YKQ",
        include: "",
        groupValue: "",
        payoutAmount: "",
        accounts: "",
        accountInError: "",
        billableMv: "",
        splitAdjustment: "",
        exclusions: "",
        discounts: "",
        cashShortages: ""
    },
    {
        userName: "Test User 3",
        email: "testuser@gmail.com",
        repcode: "YKQ",
        include: "",
        groupValue: "",
        payoutAmount: "",
        accounts: "",
        accountInError: "",
        billableMv: "",
        splitAdjustment: "",
        exclusions: "",
        discounts: "",
        cashShortages: ""
    },
]

// LEADERSHIP
export const leadershipData = [
    {
        userName: "Test User 1",
        requestedAppName: "Full Account Opening/Management",
        requestedTime: "09/07/2024",
    },
    {
        userName: "Test User 2",
        requestedAppName: "Brokersalliance MAC",
        requestedTime: "09/07/2024",
    },
    {
        userName: "Test User 1",
        requestedAppName: "TD Ameritrade institutiona's veo platform",
        requestedTime: "09/07/2024",
    },
]

export const acrylicTeam = [
    // {
    //     image: "",
    //     name: "",
    //     title: "",
    //     email: "",
    //     phone: "",
    // },
]


// ------------------------------------------------

// ------------------------------------------------