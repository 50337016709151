import React, { useContext, useEffect, useState } from 'react'
import styles from "../../Styles/ComponentStyles/MainPages/Register.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import GlobalSymbols from '../../Utils/Layout/GlobalSymbols/GlobalSymbols';

import logo from "../../Assets/Logos/login-mac-logo.png"
import GlobalAutocomplate from '../../Utils/Layout/GlobalAutocomplate/GlobalAutoComplate';
import ReCAPTCHA from "react-google-recaptcha";
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';
import { UserRegistrationPayloadModel } from '../../models/axiosModels';
import { stateOptions } from '../../Utils/Constant/UserDetailsConstant';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { useIsBelow1200 } from '../../Utils/Constant/Constant';
import ellipsis from "../../Assets/General/Ellipse.png"

const Registration = () => {

    const videoUrl = "https://player.vimeo.com/video/138115423?title=0&byline=0&portrait=0&controls=1";
    const {
        registerUserProfileDetails,
        setRegisterUserProfileDetails,
        userRegistrationDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const { showSnackbar } = useSnackbar();
    const isBelow1200 = useIsBelow1200();
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({
        username: "",
        email_address: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        agency_name: "",
        phone: "",
        state_id: "",
        how_did_you_find_us: "",
    });


    const validateForm = () => {
        const newErrors: any = {};

        // Validate username
        if (!registerUserProfileDetails.username) {
            newErrors.username = "Username is required.";
        } else if (registerUserProfileDetails.username.length < 4 || !/^[a-zA-Z0-9]+$/.test(registerUserProfileDetails.username)) {
            newErrors.username = "Username must be at least 4 characters long and alphanumeric.";
        }

        // Validate email
        if (!registerUserProfileDetails.email_address) {
            newErrors.email_address = "Email address is required.";
        } else if (!/\S+@\S+\.\S+/.test(registerUserProfileDetails.email_address)) {
            newErrors.email_address = "Please enter a valid email address.";
        }

        // Validate password
        if (!registerUserProfileDetails.password) {
            newErrors.password = "Password is required.";
        } else if (registerUserProfileDetails.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters long.";
        }

        // Validate confirm password
        if (registerUserProfileDetails.confirm_password !== registerUserProfileDetails.password) {
            newErrors.confirm_password = "Confirm password must match the password.";
        }

        // Validate first name
        if (!registerUserProfileDetails.first_name) {
            newErrors.first_name = "First name is required.";
        }

        // Validate last name
        if (!registerUserProfileDetails.last_name) {
            newErrors.last_name = "Last name is required.";
        }

        // Validate agency name
        if (!registerUserProfileDetails.agency_name) {
            newErrors.agency_name = "Agency name is required.";
        }

        // Validate phone number
        if (!registerUserProfileDetails.phone || registerUserProfileDetails.phone.length < 10) {
            newErrors.phone = "Phone number must be at least 10 digits.";
        }

        // Validate state ID
        if (!registerUserProfileDetails.state_id) {
            newErrors.state_id = "Please select a valid state.";
        }

        // Validate how did you find us
        if (!registerUserProfileDetails.how_did_you_find_us) {
            newErrors.how_did_you_find_us = "Please let us know how you found us.";
        }

        return newErrors;
    };

    const handleCreateUser = async () => {
        const newErrors = validateForm();
        setErrors(newErrors);

        // If there are errors, stop submission
        if (Object.values(newErrors).some((error) => error !== "")) {
            return;
        }

        try {
            // Create the payload from the form data
            let payload: UserRegistrationPayloadModel = {
                username: registerUserProfileDetails.username,
                email_address: registerUserProfileDetails.email_address,
                password: registerUserProfileDetails.password,
                confirm_password: registerUserProfileDetails.confirm_password,
                first_name: registerUserProfileDetails.first_name,
                last_name: registerUserProfileDetails.last_name,
                agency_name: registerUserProfileDetails.agency_name,
                phone: registerUserProfileDetails.phone,
                address_line_1: registerUserProfileDetails.address_line_1,
                address_line_2: registerUserProfileDetails.address_line_2,
                city_name: registerUserProfileDetails.city_name,
                zip_code: registerUserProfileDetails.zip_code,
                state_id: registerUserProfileDetails.state_id,
                how_did_you_find_us: registerUserProfileDetails.how_did_you_find_us,
                affiliation: registerUserProfileDetails.affiliation,
                recaptcha: registerUserProfileDetails.recaptcha,
            };

            showSnackbar("User Created Successfully", 'success')
            setTimeout(() => {
                navigate("/login")
            }, 1000);

            const response = await userRegistrationDetails(payload);

            if (response.status) {
                showSnackbar("User Created Successfully", 'success')
                setTimeout(() => {
                    navigate("/login")
                }, 1000);
            }
            else {
                console.log("Error in response", response);
            }
        }
        catch (error) {
            console.error("Error during user registration", error);
        }
    };

    const handleBlur = (field: string) => {
        const newErrors = validateForm();
        setErrors({
            ...errors,
            [field]: newErrors[field] || "",
        });
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, affiliation: string) => {
        setRegisterUserProfileDetails((prevState) => {
            const updatedAffiliations = event.target.checked ? [...prevState.affiliation, affiliation] : prevState.affiliation.filter(item => item !== affiliation);
            return { ...prevState, affiliation: updatedAffiliations };
        });
    };


    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12 }} className={`${isBelow1200 ? styles.registrationFormBelowTab : styles.siginupRelative}`}>
                    <Grid container>


                        {isBelow1200 && (
                            <>
                                <Grid size={{ xs: 11 }} className={`${styles.signupMacLogoItem}`}>
                                    <img
                                        src={logo}
                                        alt="Mac Acrylic Logo"
                                        className={styles.signupMacLogo}
                                    />
                                </Grid>
                                <Grid className={`${styles.signupElipsis}`}>
                                    <img src={ellipsis} alt="" />
                                </Grid>
                            </>
                        )}
                        {!isBelow1200 && (
                            <Grid size={{ xs: 12 }} className={`${styles.registrationBannerItem}`}>
                                <Grid container>
                                    <Grid size={{ xl: 11.5, lg: 11.5, md: 11.5, sm: 11.5, xs: 11.5 }} className={`${styles.registerHeadWrapper}`} sx={{ margin: "0 auto" }}>
                                        <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                            <Grid size={{ xl: 5.5, lg: 5.5, md: 5.5, sm: 5.5, xs: 5.5 }} className={`${styles.regBannerLeftItem}`}>
                                                <Grid container>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 10px" }}>
                                                        <img src={logo} className={`${styles.registerAcrLogo}`} alt="" />
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 10px" }}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.gblCF7FAFC} ${globalStyles.globalTextV24}`}
                                                        >
                                                            FREE Sign up for myAdvisor Cloud
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 10px" }}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.gblCCFD9E0} ${globalStyles.globalTextV17}`}
                                                            sx={{ fontWeight: "400 !important" }}
                                                        >
                                                            myAdvisor Cloud is the industry's only fully customizable advisor dashboard
                                                            designed to help advisors, and financial professionals increase efficiency and
                                                            grow business. Here will you have 24/7 access to quoting tools, carrier
                                                            contracting, applications, case management data, production information,
                                                            and a wealth of training and marketing materials.
                                                            Complete the form below
                                                            to register for a FREE 60-day trial of myAdvisorCloud. Your request will be
                                                            reviewed by our sales team and you will receive an email notification upon
                                                            approval within 1 business day.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }} sx={{ margin: "10px 0px 10px" }}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.gblCF7FAFC} ${globalStyles.globalTextV18}`}
                                                            sx={{ fontWeight: "500 !important" }}
                                                        >
                                                            Thank you and welcome!
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid size={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }}>
                                                <div className={`${styles.iframeStyles}`}>
                                                    <iframe
                                                        src={videoUrl}
                                                        frameBorder="0"
                                                        allow="autoplay; fullscreen"
                                                        allowFullScreen
                                                        title="Vimeo Video"
                                                        style={{ width: "100%", height: "100%", }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid size={{ xs: 10.5 }} className={`${styles.regFormMainItem}`}>
                            <Grid container>
                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 30px" }}>
                                    <Grid container>
                                        {isBelow1200 &&
                                            <Grid size={{ xs: 12 }} sx={{ margin: "20px auto" }}>
                                                <Typography
                                                    component={'span'}
                                                    className={`${globalStyles.globalTextV26} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gbl141D2B}`}
                                                >
                                                    FREE Sign up for myAdvisor Cloud
                                                </Typography>
                                            </Grid>
                                        }

                                        <Grid size={{ xs: 12 }} className={`${styles.regFormHeadItem} `}>
                                            <Typography
                                                component={'span'}
                                                className={`${isBelow1200 ? `${globalStyles.gblCWhite} ${globalStyles.globalTextV20}` : `${globalStyles.globalTextV24} ${globalStyles.gbl141D2B}`}`}
                                            >
                                                Account Information
                                            </Typography>
                                        </Grid>

                                        <Divider
                                            className={`${styles.regDivider}`}
                                            sx={{ borderColor: isBelow1200 ? "#ffffff" : "#1A2537B2" }}
                                        />

                                        <Grid size={{ xs: 12 }}>
                                            <Grid container sx={{ gap: '20px 30px' }}>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Grid container>
                                                                <Grid size={10}>
                                                                    <Typography
                                                                        component={'span'}
                                                                        className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                                    >
                                                                        Username <GlobalSymbols symbol="astric" />
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={2} sx={{ textAlign: "end" }}>
                                                                    <InfoOutlinedIcon sx={{ fontSize: "20px", color: isBelow1200 ? "white" : "black" }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Name'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("username")}
                                                                error={Boolean(errors.username)}
                                                                helperText={errors.username}
                                                                value={registerUserProfileDetails.username}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        username: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                E-mail Address <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Email'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("email_address")}
                                                                error={Boolean(errors.email_address)}
                                                                helperText={errors.email_address}
                                                                value={registerUserProfileDetails.email_address}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        email_address: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Create Password <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Password'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                type={showPassword ? 'text' : 'password'}
                                                                onBlur={() => handleBlur("password")}
                                                                error={Boolean(errors.password)}
                                                                helperText={errors.password}
                                                                value={registerUserProfileDetails.password}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        password: e.target.value
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                            <IconButton
                                                                                edge="start"
                                                                                size='small'
                                                                                onClick={() => { setShowPassword(!showPassword) }}
                                                                                sx={{
                                                                                    margin: "0px 10px 0px 5px",
                                                                                }}
                                                                            >
                                                                                {showPassword ?
                                                                                    <VisibilityOff
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    /> :
                                                                                    <Visibility
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    />
                                                                                }
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Confirm Password <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Password'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                onBlur={() => handleBlur("confirm_password")}
                                                                error={Boolean(errors.confirm_password)}
                                                                helperText={errors.confirm_password}
                                                                value={registerUserProfileDetails.confirm_password}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        confirm_password: e.target.value
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                            <IconButton
                                                                                edge="start"
                                                                                size='small'
                                                                                onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                                                                sx={{
                                                                                    margin: "0px 10px 0px 5px",
                                                                                }}
                                                                            >
                                                                                {showConfirmPassword ?
                                                                                    <VisibilityOff
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    /> :
                                                                                    <Visibility
                                                                                        sx={{ fontSize: "23px", color: "#718096" }}
                                                                                    />
                                                                                }
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 12 }} sx={{ margin: "0px 0px 30px" }}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} className={`${styles.regFormHeadItem} `}>
                                            <Typography
                                                component={'span'}
                                                className={`${isBelow1200 ? `${globalStyles.gblCWhite} ${globalStyles.globalTextV20}` : `${globalStyles.globalTextV24} ${globalStyles.gbl141D2B}`}`}
                                            >
                                                Your Details
                                            </Typography>
                                        </Grid>

                                        <Divider
                                            className={`${styles.regDivider}`}
                                            sx={{ borderColor: isBelow1200 ? "#ffffff" : "#1A2537B2" }}
                                        />

                                        <Grid size={{ xs: 12 }}>
                                            <Grid container sx={{ gap: '20px 30px' }}>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                First Name <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter First Name'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("first_name")}
                                                                error={Boolean(errors.first_name)}
                                                                helperText={errors.first_name}
                                                                value={registerUserProfileDetails.first_name}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        first_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Last Name <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Last Name'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("last_name")}
                                                                error={Boolean(errors.last_name)}
                                                                helperText={errors.last_name}
                                                                value={registerUserProfileDetails.last_name}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        last_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Agency Name <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Agency Name'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("agency_name")}
                                                                error={Boolean(errors.agency_name)}
                                                                helperText={errors.agency_name}
                                                                value={registerUserProfileDetails.agency_name}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        agency_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Phone <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Phone'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                onBlur={() => handleBlur("phone")}
                                                                error={Boolean(errors.phone)}
                                                                helperText={errors.phone}
                                                                value={registerUserProfileDetails.phone}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        phone: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Address Line 1
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Address'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                multiline
                                                                rows={3}
                                                                value={registerUserProfileDetails.address_line_1}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        address_line_1: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Address Line 2
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Address'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                multiline
                                                                rows={3}
                                                                value={registerUserProfileDetails.address_line_2}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        address_line_2: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Zipcode
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Zipcode'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                value={registerUserProfileDetails.zip_code}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        zip_code: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                City
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter City'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                value={registerUserProfileDetails.city_name}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        city_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                Select State <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalAutocomplate
                                                                size='small'
                                                                fullWidth
                                                                className={`${styles.regAutocommplate}`}
                                                                options={stateOptions}
                                                                placeholder='Select State'
                                                                onBlur={() => handleBlur("state_id")}  // Trigger validation when the user clicks out
                                                                error={Boolean(errors.state_id)}  // Show error if state is not selected
                                                                helperText={errors.state_id}  // Display error message
                                                                value={stateOptions?.find(option => option.value == registerUserProfileDetails.state_id) || null}
                                                                onChange={(event: any, newValue: any) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        state_id: newValue ? newValue.value : null,
                                                                    });
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        className={styles.regAutocommplateField}
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder='Select State'
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid size={{ xs: 12 }} >
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} className={`${styles.regFormHeadItem} `}>
                                            <Typography
                                                component={'span'}
                                                className={`${isBelow1200 ? `${globalStyles.gblCWhite} ${globalStyles.globalTextV20}` : `${globalStyles.globalTextV24} ${globalStyles.gbl141D2B}`}`}
                                            >
                                                Other Details
                                            </Typography>
                                        </Grid>

                                        <Divider
                                            className={`${styles.regDivider}`}
                                            sx={{ borderColor: isBelow1200 ? "#ffffff" : "#1A2537B2" }}
                                        />

                                        <Grid size={{ xs: 12 }}>
                                            <Grid container sx={{ gap: '20px 30px' }}>
                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} className={`${styles.regFieldHeadItem}`}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV16} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            >
                                                                How did you find us? <GlobalSymbols symbol="astric" />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='Enter Address'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='small'
                                                                autoComplete='off'
                                                                multiline
                                                                rows={3}
                                                                onBlur={() => handleBlur("how_did_you_find_us")}
                                                                error={Boolean(errors.how_did_you_find_us)}
                                                                helperText={errors.how_did_you_find_us}
                                                                value={registerUserProfileDetails.how_did_you_find_us}
                                                                onChange={(e) => {
                                                                    setRegisterUserProfileDetails({
                                                                        ...registerUserProfileDetails,
                                                                        how_did_you_find_us: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={`${styles.regFieldsMainItem}`}>
                                                    <FormLabel
                                                        component="legend"
                                                        sx={{ color: isBelow1200 ? "#ffffff" : "#141D2B", fontSize: "16px", fontWeight: '700', ml: "7px" }}
                                                    >Are you affiliated with</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    sx={{ padding: "3px" }}
                                                                    checked={registerUserProfileDetails.affiliation.includes("Brokers Alliance")} // Check if selected
                                                                    onChange={(event) => handleCheckboxChange(event, "Brokers Alliance")}
                                                                />
                                                            }
                                                            sx={{ marginLeft: 0, fontSize: "13px", }}
                                                            label="Brokers Alliance"
                                                            labelPlacement='end'
                                                            className={styles.regCheckbox}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    sx={{ padding: "3px" }}
                                                                    checked={registerUserProfileDetails.affiliation.includes("Brooklight Place Securities")} // Check if selected
                                                                    onChange={(event) => handleCheckboxChange(event, "Brooklight Place Securities")}
                                                                />
                                                            }
                                                            sx={{ marginLeft: 0, fontSize: "13px" }}
                                                            label="Brooklight Place Securities"
                                                            labelPlacement='end'
                                                            className={styles.regCheckbox}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    sx={{ padding: "3px" }}
                                                                    checked={registerUserProfileDetails.affiliation.includes("None")} // Check if selected
                                                                    onChange={(event) => handleCheckboxChange(event, "None")}
                                                                />
                                                            }
                                                            sx={{ marginLeft: 0, fontSize: "13px" }}
                                                            label="None"
                                                            labelPlacement='end'
                                                            className={styles.regCheckbox}
                                                        />
                                                    </FormGroup>
                                                </Grid>

                                                <Grid size={{ xs: 12 }}>
                                                    <ReCAPTCHA
                                                        className={`${styles.gRecaptcha}`}
                                                        sitekey="6LfyVJ0UAAAAAJOnWbofCE79xHEH6Rhk4mwEX00q"
                                                        onChange={(token: string | null) => {
                                                            setRegisterUserProfileDetails({
                                                                ...registerUserProfileDetails,
                                                                recaptcha: token
                                                            });
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ textAlign: "center", margin: "10px 0px 0px" }}>
                                                    <GlobalButton
                                                        value={"Register"}
                                                        size='large'
                                                        className={`${styles.registerBtn}`}
                                                        onClick={() => { handleCreateUser() }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Registration