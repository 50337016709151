import React, { ReactNode, useState } from "react";
import styles from "../../../Styles/ComponentStyles/MainPages/dashboard.module.scss";
import globalStyles from "../../../Styles/global.module.scss";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Typography } from "@mui/material";


interface dashboardNavbarProps {
  headName: string;
  onClick?: () => void;
  children?: ReactNode;
}

const DashboardNavbar = (props: dashboardNavbarProps) => {
  const { headName, onClick, children } = props;

  return (
    <>

      <Grid container className={`${styles.dashboardSubnavMainContainer}`}>
        <Grid size={{ xl: 5.5, lg: 5.5, md: 5.5, sm: 5.5, xs: "grow" }}>
          <Grid container sx={{ gap: "10px" }}>
            <Grid>
              <IconButton sx={{ padding: "0px" }} onClick={onClick}>
                <ArrowBackIcon sx={{ color: "white" }} />
              </IconButton>
            </Grid>
            <Grid className={`${styles.subNavHeadNameItem}`}>
              <Typography
                component={"span"}
                className={`${globalStyles.gblCWhite} ${globalStyles.globalTextV16} ${globalStyles.gblOnelineEllipsis} `}
              >
                {headName ? headName : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>


        <Grid size={{ xl: 5.5, lg: 5.5, md: 5.5, sm: 5.5, xs: "auto" }}>
          <Grid container className={`${globalStyles.subNavContainer}`}>
            {children}
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};

export default DashboardNavbar;
