import React, { useContext, useEffect, useRef, useState } from 'react'
import loginStyles from "../../Styles/ComponentStyles/MainPages/Signin.module.scss"
import styles from "../../Styles/ComponentStyles/MainPages/Reset.module.scss"
import globalStyles from "../../Styles/global.module.scss"

import Grid from '@mui/material/Grid2';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import ellipsis from "../../Assets/General/Ellipse.png"
import logo from "../../Assets/Logos/login-mac-logo.png"
import GlobalTextfield from '../../Utils/Layout/GlobalTextfield/GlobalTextfield';
import { GlobalUserContext, GlobalUserContextPayload } from '../../Context/GlobalUserDetailContext';
import GlobalButton from '../../Utils/Layout/GlobalButton/GlobalButton';
import OtpInput from 'react18-input-otp';
import GlobalSymbols from '../../Utils/Layout/GlobalSymbols/GlobalSymbols';
import { Margin, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from '../../Utils/Layout/GlobalSnackbar/GlobalSnackbar';
import { useNavigate } from 'react-router-dom';
import { useIsBelow1200, useIsBelow600 } from '../../Utils/Constant/Constant';
import macLogo from "../../Assets/Logos/login-mac-logo.png"


const ResetPasword = () => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const videoUrl = "https://player.vimeo.com/video/138115423?title=0&byline=0&portrait=0&controls=1";


    const {
        forgotPasswordDetails,
        setForgotPasswordDetails,
        setFlagLoader
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const isBelow1200 = useIsBelow1200();
    const isBelow600 = useIsBelow600();

    const [email, setEmail] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentStep, setCurrentStep] = useState("email")
    const [emailError, setEmailError] = useState(false);
    const [errors, setErrors] = useState({
        password: "",
        confirm_password: "",
    });
    const [timer, setTimer] = useState(120); // 2 minutes in seconds
    const [isTimerActive, setIsTimerActive] = useState(false);

    useEffect(() => {
        let intervalId: any;

        if (isTimerActive && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timer <= 0) {
            if (intervalId) clearInterval(intervalId);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isTimerActive, timer]);


    const handleSendOtp = () => {
        setFlagLoader(true)
        if (validateEmail(email)) {
            setShowOtpInput(true);
            setIsTimerActive(true);
            setTimer(120);
        } else {
            setEmailError(true)
        }
        setFlagLoader(false)
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Validate email (basic example, you can make it more robust)
    const validateEmail = (email: string) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    // Handle OTP change
    const handleOtpChange = (value: string) => {
        setForgotPasswordDetails({
            ...forgotPasswordDetails,
            otp: value
        });
    };

    const handleOtpSubmit = () => {

        const newErrors = validateForm();
        setErrors(newErrors);

        // If there are errors, stop submission
        if (Object.values(newErrors)?.some((error) => error !== "")) {
            return;
        }

        if (forgotPasswordDetails.otp === '123456') {
            showSnackbar('OTP Verified Successfully', "success");
            setTimeout(() => {
                navigate("/")
            }, 1000);
        } else {
            showSnackbar('Invalid OTP', 'error');
        }
    };

    const validateForm = () => {
        const newErrors: any = {};

        // Validate password
        if (!forgotPasswordDetails.password) {
            newErrors.password = "Password is required.";
        } else if (forgotPasswordDetails.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters long.";
        }

        // Validate confirm password
        if (forgotPasswordDetails.confirm_password !== forgotPasswordDetails.password) {
            newErrors.confirm_password = "Confirm password must match the password.";
        }

        return newErrors;
    };

    const handleBlur = (field: string) => {
        const newErrors = validateForm();
        setErrors({
            ...errors,
            [field]: newErrors[field] || "",
        });
    };

    return (
        <>
            <Grid container sx={{ height: isBelow1200 ? "100vh" : "", justifyContent: isBelow1200 ? "center" : "" }}>
                <Grid size={{ xs: 12 }} className={`${styles.resetWrapper}`}>
                    <Grid container className={`${globalStyles.fullHeight} ${loginStyles.signinMainContainer}`}>
                        {isBelow1200 &&
                            <Grid className={`${styles.ellipsisMainItem}`}>
                                <img src={ellipsis} alt="" />
                            </Grid>
                        }
                        <Grid size={{ xl: 5.8, lg: 5.8, md: 12, sm: 12, xs: 12 }}
                            className={`${isBelow1200 ? styles.resetTabBGItem : ""}`}
                        >
                            <Grid container sx={{ alignItems: isBelow1200 ? "start" : 'center', height: "100%" }}>
                                <Grid className={`${loginStyles.signinFormItem} ${styles.resetFormItem}`}>
                                    {isBelow1200 &&
                                        <>
                                            <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                                                <img src={macLogo} alt="Mac Acrylic Logo" className={`${styles.resetMacLogo}`} />
                                            </Grid>
                                        </>
                                    }

                                    <Grid size={{ xs: 12 }} className={`${styles.resetFieldMainItem}`}>
                                        <Grid container>
                                            <Grid size={{ xs: 12 }} sx={{ margin: isBelow1200 ? "0px 0px 10px" : "" }}>
                                                <Typography
                                                    component={'span'}
                                                    // className={`${globalStyles.globalTextV35} ${globalStyles.gblC171923}`}
                                                    className={`${isBelow1200 ? `${globalStyles.gblCWhite} ${globalStyles.globalTextV28}` : `${globalStyles.globalTextV35} ${globalStyles.gbl141D2B}`}`}
                                                    sx={{ fontWeight: "700" }}>
                                                    Recover your password
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>
                                                {showOtpInput ?
                                                    <>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV18} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            sx={{ fontWeight: "600 !important" }}
                                                        >
                                                            Verify the code and reset your password so you can use login credentials from BA MAC, Retirement ACE or LifeBrain.io
                                                        </Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${globalStyles.globalTextV18} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                            sx={{ fontWeight: isBelow1200 ? "400 !important" : "700 !important" }}
                                                        >
                                                            Enter the email that&nbsp;
                                                            <Typography
                                                                component={'span'}
                                                                className={`${globalStyles.globalTextV18} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923}`}
                                                                sx={{ fontWeight: "400 !important" }}
                                                            >
                                                                you used when you signed up to recover your password. You will receive a&nbsp;
                                                                <Typography
                                                                    component={'span'}
                                                                    className={`${globalStyles.globalTextV18}`}
                                                                    sx={{ fontWeight: isBelow1200 ? "400 !important" : "700 !important" }}
                                                                >
                                                                    password reset link.
                                                                </Typography>
                                                            </Typography>
                                                        </Typography>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    {currentStep === "email" ? (
                                        // Step 1: Email Input
                                        <>
                                            <Grid size={{ xs: 12 }} sx={{ margin: '25px 0px 40px' }}>
                                                <Grid container>
                                                    <Grid size={{ xs: 12 }} sx={{ mb: '5px' }}>
                                                        <Typography
                                                            component={'span'}
                                                            className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}
                                                        >
                                                            E-mail
                                                        </Typography>
                                                    </Grid>
                                                    <Grid size={{ xs: 12 }}>
                                                        <GlobalTextfield
                                                            fullWidth
                                                            placeholder='example@gmail.com'
                                                            stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                            size='medium'
                                                            autoComplete='off'
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            onBlur={() => setEmailError(false)}
                                                            error={emailError}
                                                            helperText={emailError ? "Please Enter valid email" : ""}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid size={{ xs: 12 }}>
                                                <GlobalButton
                                                    fullWidth
                                                    variant='contained'
                                                    value='Send Link'
                                                    size='large'
                                                    className={`${styles.sendLinkBtn}`}
                                                    onClick={() => {
                                                        if (validateEmail(email)) {
                                                            setCurrentStep("otp"); // Move to OTP step
                                                            setIsTimerActive(true);
                                                            setTimer(120);
                                                        } else {
                                                            setEmailError(true);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    ) : currentStep === "otp" ? (
                                        // Step 2: OTP Input
                                        <Grid size={{ xs: 12 }} sx={{ margin: "40px 0px" }}>
                                            <Grid container>
                                                <Grid size={{ xs: 12 }} sx={{ margin: '0px 0px 10px' }}>
                                                    <Grid container>
                                                        <Grid size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }} className={`${styles.verificationItem}`}>
                                                            <Grid container sx={{ rowGap: isBelow1200 ? "2px" : '5px' }}>
                                                                <Grid
                                                                    size={{ xs: 12 }}
                                                                    className={`${globalStyles.globalTextV20} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gbl1A2537}`}
                                                                >
                                                                    Verification Code
                                                                </Grid>
                                                                <Grid
                                                                    size={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
                                                                    sx={{ color: isBelow1200 ? "#B7B7B7" : "#565F6B", mt: "3px" }}
                                                                    className={`${globalStyles.globalTextV14}`}
                                                                >
                                                                    Sent to&nbsp;{email || "sample@gmail.com"}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}
                                                            className={`${globalStyles.globalTextV14} ${styles.timeRemainingItem} ${isBelow1200 ? globalStyles.gblCACACAC : globalStyles.gblC1A2537}`}
                                                            sx={{ textAlign: { sm: "end", xs: "end" } }}
                                                        >
                                                            Time Remaining:
                                                            <Typography component={'span'} sx={{ color: isBelow1200 ? "white" : "black" }}>
                                                                &nbsp;{formatTime(timer)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid size={{ xs: 12 }} className={`${styles.otpInputWrapperItem}`} sx={{ margin: "20px 0px 10px" }}>
                                                    <OtpInput
                                                        value={forgotPasswordDetails.otp}
                                                        onChange={handleOtpChange}
                                                        className={`${styles.otpInputWrapper}`}
                                                        numInputs={6}
                                                        separator={<span className={styles.separator}></span>}
                                                        isInputNum={true}
                                                    />
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                                                    <GlobalButton
                                                        value="Verify OTP"
                                                        fullWidth
                                                        size="large"
                                                        className={`${styles.sendLinkBtn}`}
                                                        onClick={() => {
                                                            if (forgotPasswordDetails.otp === '123456') {
                                                                setCurrentStep("password"); // Move to password step
                                                            } else {
                                                                showSnackbar('Invalid OTP', 'error');
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        // Step 3: Password and Confirm Password
                                        <Grid size={{ xs: 12 }} >
                                            <Grid container>
                                                <Grid size={{ xs: 12 }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                            <Grid container>
                                                                <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                    <Typography component={'span'} className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                        Password
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{ xs: 12 }}>
                                                                    <GlobalTextfield
                                                                        fullWidth
                                                                        placeholder='Enter Password'
                                                                        stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                        size='medium'
                                                                        autoComplete='off'
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        onBlur={() => handleBlur("password")}
                                                                        error={Boolean(errors.password)}
                                                                        helperText={errors.password}
                                                                        value={forgotPasswordDetails.password}
                                                                        onChange={(e) => {
                                                                            setForgotPasswordDetails({
                                                                                ...forgotPasswordDetails,
                                                                                password: e.target.value
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                    <IconButton
                                                                                        edge="start"
                                                                                        size='small'
                                                                                        onClick={() => { setShowPassword(!showPassword) }}
                                                                                        sx={{
                                                                                            margin: "0px 10px 0px 5px",
                                                                                        }}
                                                                                    >
                                                                                        {showPassword ?
                                                                                            <VisibilityOff
                                                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                                                            /> :
                                                                                            <Visibility
                                                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                                                            />
                                                                                        }
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                            <Grid container>
                                                                <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                    <Typography component={'span'} className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                        Confirm Password
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{ xs: 12 }}>
                                                                    <GlobalTextfield
                                                                        fullWidth
                                                                        placeholder='Enter Password'
                                                                        stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                        size='medium'
                                                                        autoComplete='off'
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        onBlur={() => handleBlur("confirm_password")}
                                                                        error={Boolean(errors.confirm_password)}
                                                                        helperText={errors.confirm_password}
                                                                        value={forgotPasswordDetails.confirm_password}
                                                                        onChange={(e) => {
                                                                            setForgotPasswordDetails({
                                                                                ...forgotPasswordDetails,
                                                                                confirm_password: e.target.value
                                                                            })
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                    <IconButton
                                                                                        edge="start"
                                                                                        size='small'
                                                                                        onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                                                                        sx={{
                                                                                            margin: "0px 10px 0px 5px",
                                                                                        }}
                                                                                    >
                                                                                        {showConfirmPassword ?
                                                                                            <VisibilityOff
                                                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                                                            /> :
                                                                                            <Visibility
                                                                                                sx={{ fontSize: "23px", color: "#718096" }}
                                                                                            />
                                                                                        }
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                                                    <GlobalButton
                                                        value={"Save & Reset"}
                                                        fullWidth
                                                        size='large'
                                                        className={`${styles.sendLinkBtn}`}
                                                        onClick={handleOtpSubmit}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}



                                    {/* {showOtpInput ?
                                        (
                                            <Grid size={{ xs: 12 }} sx={{ margin: "40px 0px" }}>
                                                <Grid container>
                                                    <>
                                                        <Grid size={{ xs: 12 }} sx={{ margin: '0px 0px 10px' }}>
                                                            <Grid container>
                                                                <Grid size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }} className={`${styles.verificationItem}`}>
                                                                    <Grid container sx={{ rowGap: isBelow1200 ? "2px" : '5px' }}>
                                                                        <Grid size={{ xs: 12 }} className={`${globalStyles.globalTextV20} ${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gbl1A2537}`}>
                                                                            Verification Code
                                                                        </Grid>
                                                                        <Grid
                                                                            size={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
                                                                            sx={{ color: isBelow1200 ? "#B7B7B7" : "#565F6B", mt: "3px" }}
                                                                            className={`${globalStyles.globalTextV14}`}
                                                                        >
                                                                            Sent to&nbsp;{email ? email : "sample@gmail.com"}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {showOtpInput && (
                                                                    <Grid size={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6 }}
                                                                        className={`${globalStyles.globalTextV14} ${styles.timeRemainingItem} ${isBelow1200 ? globalStyles.gblCACACAC : globalStyles.gblC1A2537}`}
                                                                        sx={{ textAlign: { sm: "end", xs: "end" } }}
                                                                    >
                                                                        Time Remaining:
                                                                        <Typography component={'span'} sx={{ color: isBelow1200 ? "white" : "black" }}>
                                                                            &nbsp;{formatTime(timer)}
                                                                        </Typography>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }} className={`${styles.otpInputWrapperItem}`} sx={{ margin: "20px 0px 10px" }}>
                                                            <OtpInput
                                                                value={forgotPasswordDetails.otp}
                                                                onChange={handleOtpChange}
                                                                className={`${styles.otpInputWrapper}`}
                                                                numInputs={6}  // Number of OTP fields
                                                                separator={<span className={styles.separator}></span>}
                                                                isInputNum={true}  // Only allow numbers
                                                            />
                                                        </Grid>
                                                    </>
                                                    <Grid size={{ xs: 12 }}>
                                                        <Grid container>
                                                            <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                        <Typography component={'span'} className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                            Password
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <GlobalTextfield
                                                                            fullWidth
                                                                            placeholder='Enter Password'
                                                                            stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                            size='medium'
                                                                            autoComplete='off'
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            onBlur={() => handleBlur("password")}
                                                                            error={Boolean(errors.password)}
                                                                            helperText={errors.password}
                                                                            value={forgotPasswordDetails.password}
                                                                            onChange={(e) => {
                                                                                setForgotPasswordDetails({
                                                                                    ...forgotPasswordDetails,
                                                                                    password: e.target.value
                                                                                })
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                        <IconButton
                                                                                            edge="start"
                                                                                            size='small'
                                                                                            onClick={() => { setShowPassword(!showPassword) }}
                                                                                            sx={{
                                                                                                margin: "0px 10px 0px 5px",
                                                                                            }}
                                                                                        >
                                                                                            {showPassword ?
                                                                                                <VisibilityOff
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                /> :
                                                                                                <Visibility
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px 10px" }}>
                                                                <Grid container>
                                                                    <Grid size={{ xs: 12 }} sx={{ mb: "5px" }}>
                                                                        <Typography component={'span'} className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}>
                                                                            Confirm Password
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid size={{ xs: 12 }}>
                                                                        <GlobalTextfield
                                                                            fullWidth
                                                                            placeholder='Enter Password'
                                                                            stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                            size='medium'
                                                                            autoComplete='off'
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            onBlur={() => handleBlur("confirm_password")}
                                                                            error={Boolean(errors.confirm_password)}
                                                                            helperText={errors.confirm_password}
                                                                            value={forgotPasswordDetails.confirm_password}
                                                                            onChange={(e) => {
                                                                                setForgotPasswordDetails({
                                                                                    ...forgotPasswordDetails,
                                                                                    confirm_password: e.target.value
                                                                                })
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="start" sx={{ margin: '0px', borderLeft: "1px solid #CFD9E0" }}>
                                                                                        <IconButton
                                                                                            edge="start"
                                                                                            size='small'
                                                                                            onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                                                                            sx={{
                                                                                                margin: "0px 10px 0px 5px",
                                                                                            }}
                                                                                        >
                                                                                            {showConfirmPassword ?
                                                                                                <VisibilityOff
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                /> :
                                                                                                <Visibility
                                                                                                    sx={{ fontSize: "23px", color: "#718096" }}
                                                                                                />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid size={{ xs: 12 }} sx={{ margin: "20px 0px" }}>
                                                        <GlobalButton
                                                            value={"Save & Reset"}
                                                            fullWidth
                                                            size='large'
                                                            className={`${styles.sendLinkBtn}`}
                                                            onClick={handleOtpSubmit}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid size={{ xs: 12 }} sx={{ margin: '25px 0px 40px' }}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 12 }} sx={{ mb: '5px' }}>
                                                            <Typography
                                                                component={'span'}
                                                                className={`${isBelow1200 ? globalStyles.gblCWhite : globalStyles.gblC171923} ${globalStyles.globalTextV16}`}
                                                            >
                                                                E-mail
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={{ xs: 12 }}>
                                                            <GlobalTextfield
                                                                fullWidth
                                                                placeholder='example@gmail.com'
                                                                stylingClassName={`${globalStyles.globalFieldStyles}`}
                                                                size='medium'
                                                                autoComplete='off'
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                onBlur={() => setEmailError(false)}
                                                                error={emailError}
                                                                helperText={emailError ? "Please Enter valid email" : ""}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid size={{ xs: 12 }} >
                                                    <GlobalButton
                                                        fullWidth
                                                        variant='contained'
                                                        value='Send Link'
                                                        size='large'
                                                        className={`${styles.sendLinkBtn}`}
                                                        onClick={handleSendOtp}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    } */}
                                </Grid>
                            </Grid>
                        </Grid>

                        {!isBelow1200 && (
                            <Grid size={{ xl: 5.8, lg: 5.8, md: 0, sm: 0, xs: 0 }} className={`${loginStyles.signinBgWrapper}`}>
                                <Grid container>
                                    <Grid className={`${loginStyles.signinElipsis}`}>
                                        <img src={ellipsis} alt="" />
                                    </Grid>
                                    <Grid size={{ xs: 10 }} className={`${loginStyles.signinBgSubWrapper}`}>
                                        <Grid container>
                                            <Grid size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
                                                <img src={logo} style={{ width: "auto", height: "50px" }} alt="" />
                                            </Grid>
                                            <Grid className={`${loginStyles.signinIframeItem}`}>
                                                <iframe
                                                    ref={iframeRef}
                                                    src={videoUrl}
                                                    frameBorder="0"
                                                    allow="autoplay; fullscreen"
                                                    allowFullScreen
                                                    title="Vimeo Video"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid className={`${loginStyles.signinIntroText}`}
                                                sx={{ width: "500px" }}
                                            >
                                                <Typography component={'span'} >
                                                    Introducing myAdvisorCloud.
                                                </Typography>
                                            </Grid>
                                            <Grid className={`${loginStyles.signinIntroSubText}`}
                                                sx={{ width: "500px" }}
                                            >
                                                <Typography component={'span'}>
                                                    The ONLY customized agent login portal in our industry offered by
                                                    a brokerage that understands what innovation really means.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ResetPasword