import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import MainComponent from '../Pages/MainComponent';
import Member from '../Pages/Member/Member';
import Leaderboard from '../Pages/Leaderboard/Leaderboard';
import Dashboard from '../Pages/Dashboard/Dashboard';
import DashboardHome from '../Pages/Dashboard/DashboardHome/DashboardHome';
import Users from '../Pages/Member/Users/Users';
import UserGroup from '../Pages/Member/UsersGroup/UsersGroup';
import Signin from '../Pages/Signin/Signin';
import RecentlyRegisteredUser from '../Pages/Member/RecentlyRegisteredUser/RecentlyRegisteredUser';
import { GlobalUserContext, GlobalUserContextPayload } from '../Context/GlobalUserDetailContext';
import GlobalLoader from '../Utils/Layout/GlobalLoader/GlobalLoader';
import MacApp from '../Pages/Member/MacApp/MacApp';
import ViewUsers from '../Pages/Member/Users/ViewUsers';
import ResetPassword from '../Pages/ResetPasword/ResetPassword';
import Registration from '../Pages/Registration/Registration';
import MarketingAndSalesResources from '../Pages/Dashboard/DashboardMarketingAndSalesResources/DashboardMarketingAndSalesResources';
import DashboardPlan401k from '../Pages/Dashboard/DashboardPlan401k/DashboardPlan401k';
import DashboardRevenueApp from '../Pages/Dashboard/DashboardRevenueApp/DashboardRevenueApp';
import RevenueApp from '../Pages/RevenueApp/RevenueApp';
import Profile from '../Pages/Profile/Profile';
import EditGroup from '../Pages/Member/EditGroup/EditGroup';
import { DashboardContext, DashboardDetailPayload } from '../Context/DashboardDetailContext';
import { useSnackbar } from '../Utils/Layout/GlobalSnackbar/GlobalSnackbar';

const Routes = () => {
    const [flagLoggedIn, setFlagLoggedIn] = useState(false)
    const {
        userLoggedInFlag,
        userDetails,
        flagLoader,
        setFlagLoader,
        userProfileDetails,
        setUserProfileDetails
    } = useContext(GlobalUserContext) as GlobalUserContextPayload

    const {
        dashboardDetails,
        setDashboardDetails
    } = useContext(DashboardContext) as DashboardDetailPayload

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isInitializing, setIsInitializing] = useState(true);


    React.useLayoutEffect(() => {
        const initializeAuth = async () => {
            try {
                setFlagLoader(true);
                const response = await userDetails();
                if (response?.status && response?.data) {
                    const userMeResponse = response.data
                    setUserProfileDetails({
                        ...userProfileDetails,
                        flagLoggedIn: true,
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        apps: response.data.apps
                    });
                    if (userMeResponse.apps) {
                        setDashboardDetails({ apps: userMeResponse.apps });
                    }
                    setFlagLoader(false);
                }
                else {
                    setUserProfileDetails(prevState => ({
                        ...prevState,
                        flagLoggedIn: false
                    }));
                    setFlagLoader(false);

                    // const errorMessage = response?.data?.error?.message || 'Something Went Wrong!';
                    // showSnackbar(errorMessage, 'error');
                }
            } catch (error) {
                console.error('Auth initialization error:', error);
                setUserProfileDetails(prevState => ({
                    ...prevState,
                    flagLoggedIn: false
                }));
                navigate('/');
                setFlagLoader(false);
            } finally {
                setFlagLoader(false);
                setIsInitializing(false);
            }
        };

        initializeAuth();
    }, []);

    if (isInitializing) {
        return <GlobalLoader />;
    }
    const isAppInstalled = (appName: any) => {
        return userProfileDetails?.apps?.some(app => app.appName == appName && app.flagInstalled);
    };

    return (
        <>
            {(flagLoader || userProfileDetails.flagLoggedIn == null) && <GlobalLoader />}

            <RouterRoutes>
                {userProfileDetails.flagLoggedIn ? (
                    <Route path="/" element={<MainComponent />}>
                        <Route index element={<DashboardHome />} />
                        <Route path="dashboard" element={<Dashboard />}>
                            <Route index element={<DashboardHome />} />
                            {isAppInstalled('Marketing and Sales Resources') && <Route path="marketing-and-sales" element={<MarketingAndSalesResources />} />}
                            {isAppInstalled('401(k) Plan Fee Comparison') && <Route path="plan401k" element={<DashboardPlan401k />} />}
                            <Route path="revenue" element={<DashboardRevenueApp />} />
                        </Route>
                        {userProfileDetails.user.flagAdmin && (
                            <>
                                <Route path="member" element={<Member />}>
                                    <Route index element={<Users />} />
                                    <Route path="users" element={<Users />} />
                                    <Route path="viewUsers" element={<ViewUsers />} />
                                    <Route path="usergroups" element={<UserGroup />} />
                                    <Route path="recently-registered-user" element={<RecentlyRegisteredUser />} />
                                    <Route path="mac-app" element={<MacApp />} />
                                </Route>
                                <Route path="plan401kfee" element={<DashboardPlan401k />} />
                                <Route path="revenue" element={<DashboardRevenueApp />} />
                                {/* <Route path="revenueapp" element={<RevenueApp />} /> */}
                                <Route path="leaderboard" element={<Leaderboard />} />
                                <Route path="profile" element={<Profile />} />
                            </>
                        )}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                ) : (
                    <>
                        <Route path="/" element={<Signin />} />
                        <Route path="login" element={<Signin />} />
                        <Route path="register" element={<Registration />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </>
                )}
            </RouterRoutes>
        </>
    );
}

export default Routes;
